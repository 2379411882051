import Rails from '@rails/ujs';
import _ from 'lodash-contrib';
import { init as initTooltips } from '../packs/tooltips';
import { Controller } from '@hotwired/stimulus';

const debounce = require('debounce');

export default class extends Controller {
  static targets = ['form', 'results', 'clearFilters', 'spinner'];

  static values = {
    replaceState: { type: Boolean, default: false },
    wait: { type: Number, default: 350 },
  };

  initialize() {
    this._debouncedSubmit = debounce(this.submit, this.waitValue);
    this.updateClearFiltersState(window.location.href);
  }

  submit() {
    this.startLoading();

    Rails.fire(this.formTarget, 'submit');
  }

  debouncedSubmit() {
    this.startLoading();
    this._debouncedSubmit();
  }

  clear() {
    $(this.formTarget)
      .find('input:text, input[type="number"], input[type="time"]')
      .val('')
      .trigger('change');

    $(this.formTarget)
      .find('input:radio, input:checkbox')
      .prop('checked', false)
      .trigger('change');

    $(this.formTarget)
      .find('select')
      .each((index, elem) => {
        // If the select is a select2 input then clear the value,
        // otherwise select the first option
        if ($(elem).data('select2')) {
          $(elem).val('').trigger('change');
        } else {
          $(elem)
            .find('option:enabled:first')
            .prop('selected', true)
            .trigger('change');
        }
      });

    $(this.formTarget)
      .find('[data-search-clear-value]')
      .each((index, elem) => {
        $(elem).val($(elem).data('search-clear-value')).trigger('change');
      });

    this.updateClearFiltersState(window.location.origin);
    this.submit();
  }

  onSuccess(e) {
    let [, , xhr] = e.detail;
    this.resultsTarget.innerHTML = xhr.response;
    this.stopLoading();
    this.replaceState(xhr.responseURL);
    this.updateClearFiltersState(xhr.responseURL);
    this.dispatchEvent(xhr.responseURL);
    initTooltips();
  }

  updateClearFiltersState(url) {
    if (this.hasClearFiltersTarget) {
      if (
        _.isEmpty(this.filterQueryParams(url, this.isParamClearable.bind(this)))
      ) {
        this.clearFiltersTarget.classList.add('d-none');
      } else {
        this.clearFiltersTarget.classList.remove('d-none');
      }
    }
  }

  replaceState(url) {
    if (this.replaceStateValue) {
      const urlObject = new URL(url);
      let query = _.toQuery(this.filterQueryParams(url));
      query = query ? '?' + query : urlObject.pathname;
      window.history.replaceState(null, null, query);
    }
  }

  filterQueryParams(url, filter = _.identity) {
    const urlObject = new URL(url);
    const query = urlObject.searchParams.toString();

    if (!query) {
      return {};
    }

    const queryObject = _.fromQuery(query);
    return _.pickBy(queryObject, filter);
  }

  isParamClearable(value, key) {
    return value && key !== 'page' && key !== 'sort' && key !== 'direction';
  }

  dispatchEvent(url) {
    const event = new CustomEvent('search:success', {
      detail: this.filterQueryParams(url),
    });
    window.dispatchEvent(event);
  }

  startLoading() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove('d-none');
      if (this.hasResultsTarget) {
        this.resultsTarget.classList.add('d-none');
      }
    }
  }

  stopLoading() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add('d-none');
      if (this.hasResultsTarget) {
        this.resultsTarget.classList.remove('d-none');
      }
    }
  }
}
