import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="dashboard-social-impact-graph"
export default class extends Controller {
  static values = {
    defaultGrouping: String, // one of: 'date', 'activity'
  };

  static targets = ['groupingSelect'];

  // Types
  declare defaultGroupingValue: 'date' | 'activity';
  declare readonly groupingSelectTarget: HTMLSelectElement;

  // Custom properties and methods
  private grouping: string = 'date';

  connect() {
    super.connect();

    this.setupEventListeners();
  }

  private setupEventListeners() {
    const handleGroupingChange = (e: Event) => {
      const element = e.target as HTMLSelectElement;
      this.grouping = element.value;

      this.notifyGraphFilterChanged();
    };

    this.groupingSelectTarget.addEventListener('change', handleGroupingChange);
  }

  private notifyGraphFilterChanged() {
    this.dispatch('graph-specific-filter-changed', {
      detail: {
        graphName: 'social_impact',
        selectedFilters: {
          grouping: this.grouping,
        },
      },
    });
  }
}
