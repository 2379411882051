// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import 'controllers';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './inactivity_monitor';
import 'jquery-ui';
import 'jquery-ui-dist/jquery-ui.css';
import '../stylesheets/application';
import 'moment';
import './tooltips';
import { Turbo } from '@hotwired/turbo-rails';
Turbo.session.drive = false;
import './modals';
import './select2';
import 'intl-tel-input/build/css/intlTelInput.css';
import './apexcharts';

require.context('../images', true);

Rails.start();
ActiveStorage.start();

// Google maps callback for stimulus controllers so we know
// we have access to the google object
window.dispatchMapsEvent = function (...args) {
  const event = document.createEvent('Events');
  event.initEvent('google-maps-callback', true, true);
  event.args = args;
  window.dispatchEvent(event);
};
