import { Controller } from '@hotwired/stimulus';
import _ from 'lodash-contrib';

// Connects to data-controller="acceptance-status-summary"
export default class extends Controller {
  static targets = ['summary', 'acceptanceStatusInputGroup'];

  /**
   * @type {Array<{ acceptanceStatus: string, recordId: number }>}
   */
  acceptanceStatusDiff = [];

  acceptanceStatusInputGroupTargetConnected(target) {
    this.#applyAcceptanceStatusDiff(target);
  }

  /**
   * Determine summary status as soon as {@link this.summaryTarget} is connected to the DOM.
   *
   * @param {Element} target - Summary target element that's been connected.
   */
  summaryTargetConnected(target) {
    this.#setSummaryLabels(target);
  }

  setAllSummaryLabels() {
    this.summaryTargets.forEach((target) => {
      this.#setSummaryLabels(target);
    });
  }

  /**
   * Call this within any controller, by
   *    this.dispatch('rememberStatusChange', {
   *      target: 'acceptance-status-summary',
   *      detail: { acceptanceStatus: ..., recordId: ... }
   *    })
   *
   * @param {{ acceptanceStatus: string, recordId: number | string }} detail
   */
  rememberStatusChange({ detail }) {
    const { acceptanceStatus, recordId } = detail;
    const diff = { acceptanceStatus, recordId: parseInt(recordId, 10) };

    const foundIndex = this.acceptanceStatusDiff.findIndex(
      (savedDiff) => savedDiff.recordId === diff.recordId
    );
    if (foundIndex !== -1) {
      // Update existing
      this.acceptanceStatusDiff[foundIndex] = diff;
    } else {
      this.acceptanceStatusDiff.push(diff);
    }
  }

  setAllStatusChange(e) {
    const row = e.target.closest('tr');
    const summaryTarget = $(row).find('.status-summary').get(0);
    this.#setSummaryLabels(summaryTarget);
  }

  statusChange(e) {
    const row = e.target.closest('tr');
    const parent = row.dataset.parent;
    const summaryTarget = $(`tr[data-child='${parent}']`)
      .find('.status-summary')
      .get(0);
    this.#setSummaryLabels(summaryTarget);

    const recordId = e.target.dataset.recordId;
    const acceptanceStatus = e.target.value;
    this.rememberStatusChange({ detail: { acceptanceStatus, recordId } });
  }

  /**
   * @param {Element} summaryTarget - Status summary target. See: _status_summary.html.haml
   */
  #setSummaryLabels(summaryTarget) {
    const childId = summaryTarget.dataset.children;
    const $childRows = $(`tr[data-parent='${childId}']`);
    const values = $childRows.find('input:checked').map((_index, input) => {
      return input.value;
    });
    const counts = _.countBy(values);
    $(summaryTarget)
      .find('span')
      .each((_index, circle) => {
        const status = circle.dataset.status;
        $(summaryTarget)
          .find(`.status-summary-${status}`)
          .html(
            counts[status] ? (counts[status] > 99 ? '99+' : counts[status]) : 0
          );
      });
  }

  /**
   * Apply any remembered acceptance status changes to the {@link target}'s
   * input.
   *
   * @param target - Acceptance status input group
   */
  #applyAcceptanceStatusDiff(target) {
    const recordId = parseInt(target.dataset.recordId, 10);
    const diffFound = this.acceptanceStatusDiff.find(
      (d) => d.recordId === recordId
    );
    if (!diffFound) {
      // No diff to apply for this input, skip
      return;
    }

    // There's a diff we can apply
    // Get the input for the diff and apply by marking it as checked
    const { acceptanceStatus } = diffFound;
    const inputId = `#${acceptanceStatus}_${recordId}`;
    const inputElement = target.querySelector(inputId);

    if (!inputElement.disabled) {
      inputElement.checked = true;
    }
  }
}
