import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="conflicting-booking-selection"
export default class extends Controller {
  static targets = ['terminateToggle', 'retainToggle', 'save'];

  terminateAll(event) {
    const scope = event.target.closest('.table');
    if (scope) {
      this.terminateToggleTargets.forEach((target) => {
        if (scope.contains(target)) {
          target.checked = true;
        }
      });
      this.retainToggleTargets.forEach((target) => {
        if (scope.contains(target)) {
          target.checked = false;
        }
      });
    } else {
      this.terminateToggleTargets.forEach((target) => (target.checked = true));
      this.retainToggleTargets.forEach((target) => (target.checked = false));
    }
  }

  retainAll(event) {
    const scope = event.target.closest('.table');
    if (scope) {
      this.terminateToggleTargets.forEach((target) => {
        if (scope.contains(target)) {
          target.checked = false;
        }
      });
      this.retainToggleTargets.forEach((target) => {
        if (scope.contains(target)) {
          target.checked = true;
        }
      });
    } else {
      this.terminateToggleTargets.forEach((target) => (target.checked = false));
      this.retainToggleTargets.forEach((target) => (target.checked = true));
    }
  }

  terminateAllAndSubmit(event) {
    this.terminateAll(event);
    this.saveTarget.click();
  }

  retainAllAndSubmit(event) {
    this.retainAll(event);
    this.saveTarget.click();
  }
}
