import Tooltip from 'bootstrap/js/dist/tooltip';

export function init(element = document) {
  const tooltipTriggerList = [].slice.call(
    element.querySelectorAll(
      '[data-bs-toggle="tooltip"]:not([data-tooltip-skip-auto-enable])'
    )
  );
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });
}

$(() => init());
$(document).on('turbo:frame-render', (e) => init(e.target));
