import { Controller } from '@hotwired/stimulus';
import Tooltip from 'bootstrap/js/dist/tooltip';

// Connects to data-controller="payment--invoice-download"
export default class extends Controller {
  static targets = ['link', 'tooltip'];

  static values = {
    instalmentsSelected: Boolean,
  };

  connect() {
    if (this.instalmentsSelectedValue) {
      this.instalmentsOptionSelected();
    } else {
      this.instalmentsReset();
    }
  }

  instalmentsOptionSelected(e) {
    if (e) {
      this.#updateHref(e.detail.instalments);
    }
    this.#toggleLink(true);
    this.#toggleTooltip(false);
  }

  instalmentsReset() {
    this.#toggleLink(false);
    this.#toggleTooltip(true);
  }

  #toggleLink(enabled) {
    this.linkTarget.classList.toggle('disabled', !enabled);
    this.linkTarget.classList.toggle('pe-auto', enabled);
    this.linkTarget.disabled = !enabled;
    if (enabled) {
      this.linkTarget.removeAttribute('tabindex');
    } else {
      this.linkTarget.tabIndex = -1;
    }
  }

  #toggleTooltip(enabled) {
    if (enabled) {
      Tooltip.getOrCreateInstance(this.tooltipTarget).enable();
    } else {
      // We still need to create the instance so that the title attribute is removed
      Tooltip.getOrCreateInstance(this.tooltipTarget).disable();
    }
  }

  #updateHref(payByInstalments) {
    const url = new URL(this.linkTarget.href);
    const params = url.searchParams;
    params.set('pay_by_instalments', payByInstalments);
    url.search = params.toString();
    this.linkTarget.href = url.toString();
  }
}
