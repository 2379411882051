import PlacesAutocomplete from 'stimulus-places-autocomplete';

export default class extends PlacesAutocomplete {
  static targets = super.targets.concat(['addressLine1', 'addressLine2']);
  static values = {
    country: { type: Array, default: ['uk'] },
  };

  placeChanged() {
    super.placeChanged();

    const addressComponents = this.place.address_components;

    if (addressComponents !== undefined) {
      const formattedAddress = this.formatAddressComponents(addressComponents);

      this.setAddressComponents(formattedAddress, this.place.name);
    }
  }

  setAddressComponents(address, name) {
    super.setAddressComponents(address);

    if (this.hasCityTarget) {
      this.cityTarget.value = address.postal_town || address.locality || '';
    }

    if (this.hasAddressLine1Target) {
      let streetAddress = '';

      if (address.street_number) streetAddress = `${address.street_number} `;
      if (address.route) streetAddress += address.route;

      if (name) {
        this.addressLine1Target.value = name;
        if (this.hasAddressLine2Target) {
          this.addressLine2Target.value = streetAddress;
        }
      } else {
        this.addressLine1Target.value = streetAddress;
      }
    }
  }

  get autocompleteOptions() {
    return {
      fields: ['address_components', 'geometry', 'name'],
      componentRestrictions: {
        country: this.countryValue,
      },
    };
  }
}
