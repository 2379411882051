import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['tab'];

  toggle(e) {
    const contentTarget = $(e.target).data('content-target');
    window.toggleTab(contentTarget, true);
  }
}
