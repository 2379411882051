import $ from 'jquery';
import _ from 'lodash';
import { Controller } from '@hotwired/stimulus';

const debounce = require('debounce');

export default class extends Controller {
  static targets = [
    'inputName',
    'buttonNameSearch',
    'inputFacilityType',
    'buttonFacilityTypeSearch',
  ];

  static values = {
    hierarchyId: String,
  };

  initialize() {
    this.hierarchyIdTarget = document.querySelector(this.hierarchyIdValue);
    this.hierarchyContent =
      this.hierarchyIdTarget.querySelector('#hierarchy-content');
    this.spinner = this.hierarchyIdTarget.querySelector('#spinner');

    this.setupNameSearchEvents();
    this.setupFaciltyTypeSearchEvents();

    document.querySelectorAll('.form-check-input').forEach((check) => {
      check.addEventListener('change', () => {
        this.search();
      });
    });
  }

  setupNameSearchEvents() {
    this.inputNameTarget.addEventListener(
      'keyup',
      debounce(() => {
        this.search();
      }, 350)
    );

    this.inputNameTarget.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });

    this.inputNameTarget.addEventListener('search', this.search.bind(this));
    this.buttonNameSearchTarget.addEventListener(
      'click',
      this.search.bind(this)
    );
  }

  setupFaciltyTypeSearchEvents() {
    // Resorted to JQuery for dynamic element event handling
    $(this.inputFacilityTypeTarget).on('change', () => {
      this.search();
    });

    this.buttonFacilityTypeSearchTarget.addEventListener(
      'click',
      this.search.bind(this)
    );
  }

  search() {
    this.startLoading();

    try {
      const nameQuery = this.inputNameTarget.value.toLowerCase();
      const typeQuery = $(this.inputFacilityTypeTarget).val();
      const statusQuery = document.querySelector(
        'input.form-check-input:checked'
      ).value;

      const rows = document.querySelectorAll(
        '.accordion-body .facilities-hierarchy--row'
      );

      // All the elements that match the queries
      let elementMatches = _.filter(rows, function (row) {
        const name = row.dataset.searchName.toLowerCase();
        const facilityType = row.dataset.searchFacilityType;
        const active = row.dataset.searchActive;

        const activeMatch = () => {
          if (statusQuery == 'discarded') return active == 'false';
          if (statusQuery == 'kept') return active == 'true';

          return true;
        };

        const typeMatch = () => {
          if (!_.isEmpty(typeQuery)) {
            if (facilityType) {
              return typeQuery.includes(facilityType);
            } else {
              // Exclude groups
              return false;
            }
          } else {
            return true;
          }
        };

        return name.includes(nameQuery) && activeMatch() && typeMatch();
      });

      rows.forEach(
        function (row) {
          let match = _.some(elementMatches, function (element) {
            return row.contains(element);
          });

          if (match) {
            this.showRow(row);

            // Only expand if we're searching by name or type
            if (!_.isEmpty(nameQuery) || !_.isEmpty(typeQuery))
              this.expandRow(row);
          } else {
            this.hideRow(row);
          }
        }.bind(this)
      );
    } finally {
      this.stopLoading();
    }
  }

  showRow(row) {
    // leafs have d-flex which will need to be removed / added when hiding / showing
    row.style.display = 'block';
    if (row.classList.contains('leaf')) row.classList.add('d-flex');
  }

  hideRow(row) {
    row.style.display = 'none';
    if (row.classList.contains('leaf')) row.classList.remove('d-flex');
  }

  expandRow(row) {
    const toExpand = row.querySelector('.accordion-collapse:not(.show)');

    if (toExpand) {
      const button = row.querySelector('.accordion-button.collapsed');
      toExpand.classList.add('show');

      if (button) button.classList.remove('collapsed');
    }
  }

  startLoading() {
    this.spinner.classList.remove('d-none');
    this.hierarchyContent.classList.add('d-none');
  }

  stopLoading() {
    this.spinner.classList.add('d-none');
    this.hierarchyContent.classList.remove('d-none');
  }
}
