import 'select2';
import $ from 'jquery';

$.fn.select2.defaults.set('theme', 'bootstrap-5');

function init(element = document) {
  element.querySelectorAll('select[data-select2-input]').forEach((select) => {
    $(select).select2({
      width: $(this).data('width') ? $(this).data('width') : 'style',
      placeholder: $(select).attr('placeholder'),
    });
  });
  $(document).on('select2:open', () => {
    ((list) => list[list.length - 1])(
      document.querySelectorAll(
        '.select2-container--open .select2-search__field'
      )
    ).focus();
  });
}

$(() => init());
$(document).on('turbo:frame-render', (e) => init(e.target));
