import consumer from '../channels/consumer';
const debounce = require('debounce');

const SECONDS_IN_MINUTE = 60;
const MS_IN_MINUTE = 60000;
const MS_IN_SECOND = 1000;
const COUNTDOWN_MINUTES = 1;
let RESERVATION_LIMIT_MINUTES;
let countdownTimeout;
let countdownInterval;
let countdownCounter;
let reservationChannel;
let activeInLast60Seconds = false;
let updatedAtTimestamp;
let triggerActivityAlertCheckInterval;

$(() => {
  const bookingRequestId = $('#inactivity-alert').data('booking-request-id');
  setReservationChannel(bookingRequestId);
  initialiseTimeLimits();
});

const initialiseTimeLimits = () => {
  RESERVATION_LIMIT_MINUTES = $('#inactivity-alert').data('reservation-limit');
};

const debounceActivityDetected = debounce(() => activityDetected(), 500);

const setMoveEventListeners = () => {
  const events = ['mousemove', 'mousedown', 'keydown', 'touchstart', 'scroll'];
  events.forEach((e) => {
    document.addEventListener(e, debounceActivityDetected, true);
  });
};

const removeMoveEventListeners = () => {
  const events = ['mousemove', 'mousedown', 'keydown', 'touchstart', 'scroll'];
  events.forEach((e) => {
    document.removeEventListener(e, debounceActivityDetected, true);
  });
};

const activityDetected = () => {
  activeInLast60Seconds = true;
  // if activity is within 60 seconds of the alert showing touch booking immediately, else will be triggered by the ActionCable subscription
  if (!updatedAtTimestamp) return;
  const diff = calculateDiff();
  if (
    diff <= (COUNTDOWN_MINUTES + 1) * MS_IN_MINUTE &&
    diff > COUNTDOWN_MINUTES * MS_IN_MINUTE
  ) {
    touchBookingRequestIfActive(true);
  }
};

const calculateDiff = () => {
  return (
    updatedAtTimestamp + RESERVATION_LIMIT_MINUTES * MS_IN_MINUTE - Date.now()
  );
};

const triggerActivityAlertCheck = () => {
  if (!updatedAtTimestamp) return;
  const diff = calculateDiff();
  if (diff <= COUNTDOWN_MINUTES * MS_IN_MINUTE) {
    diff > 0 ? triggerAlert(diff) : inactivityAlertEnded();
  }
};

const setupTriggerActivityAlertCheck = () => {
  clearInterval(triggerActivityAlertCheckInterval);
  triggerActivityAlertCheckInterval = setInterval(
    triggerActivityAlertCheck,
    MS_IN_SECOND
  );
};

const touchBookingRequestIfActive = (force = false) => {
  if (
    !reservationChannel ||
    (activeInLast60Seconds === false && force === false)
  ) {
    return;
  }
  activeInLast60Seconds = false;
  reservationChannel.send({ message: 'touch' });
};

const triggerAlert = (timeRemainingMS) => {
  removeMoveEventListeners();
  clearInterval(triggerActivityAlertCheckInterval);
  clearCountdownInterval();
  countdownCounter = Math.round(
    (timeRemainingMS / MS_IN_MINUTE) * SECONDS_IN_MINUTE
  );
  // Update countdown text every second
  setCountDownText();
  countdownInterval = setInterval(setCountDownText, MS_IN_SECOND);
  showInactivityAlert();
  // Show reservations removed alert at end of countdown
  countdownTimeout = setTimeout(inactivityAlertEnded, timeRemainingMS);
};

const inactivityAlertEnded = () => {
  clearInterval(triggerActivityAlertCheckInterval);
  clearCountdownInterval();
  showInactivityAlertEnded();
  hideInactivityAlert();
};

const clearCountdownInterval = () => {
  clearInterval(countdownInterval);
  clearTimeout(countdownTimeout);
};

const setCountDownText = () => {
  countdownCounter =
    countdownCounter > 0 ? (countdownCounter -= 1) : countdownCounter;
  $('#inactivity-time').text(`${countdownCounter} seconds`);
};

const showInactivityAlert = () => {
  addCountDownEventListeners();
  $('#inactivity-alert').removeClass('d-none');
};

const hideInactivityAlert = () => {
  $('#inactivity-alert').addClass('d-none');
};

const showInactivityAlertEnded = () => {
  $('#inactivity-alert-ended').removeClass('d-none');
  hideInactivityAlert();
};

const hideInactivityAlertEnded = () => {
  $('#inactivity-alert-ended').addClass('d-none');
};

const showInactivityAlertRemoved = () => {
  addBookingRemovedEventListeners();
  $('#inactivity-alert-removed').removeClass('d-none');
};

const reservationsRemoved = () => {
  removeCountDownEventListeners();
  hideInactivityAlertEnded();
  showInactivityAlertRemoved();
};

const listenerHandler = () => {
  clearCountdownInterval();
  touchBookingRequestIfActive(true);
  removeCountDownEventListeners();
  hideInactivityAlert();
  hideInactivityAlertEnded();
};

const addCountDownEventListeners = () => {
  const events = ['mousedown', 'keydown', 'touchstart'];

  events.forEach((e) => {
    document.addEventListener(e, listenerHandler, true);
  });
};

const removeCountDownEventListeners = () => {
  const events = ['mousedown', 'keydown', 'touchstart'];
  events.forEach((e) => {
    document.removeEventListener(e, listenerHandler, true);
  });
};

const addBookingRemovedEventListeners = () => {
  const events = ['mousedown', 'keydown', 'touchstart'];

  events.forEach((e) => {
    document.addEventListener(e, () => window.location.reload(), true);
  });
};

const setReservationChannel = (bookingRequestId) => {
  if (!bookingRequestId) return;
  reservationChannel = consumer.subscriptions.create(
    { channel: 'ReservationsChannel', id: bookingRequestId },
    {
      connected() {
        // Called when the subscription is ready for use on the server
        touchBookingRequestIfActive(true);
        keepActiveDuringPayment();
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        if (data.message === 'exists') {
          setUpdatedAtTimestampFromData(data);
          setMoveEventListeners();
          touchBookingRequestIfActive();
          setupTriggerActivityAlertCheck();
        }
        if (data.message === 'removed') {
          reservationsRemoved();
        }
      },
    }
  );
};

const setUpdatedAtTimestampFromData = (data) => {
  updatedAtTimestamp = new Date(data.booking_request.updated_at).getTime();
};

/**
 * @param {string} id Booking request ID
 */
const appendBookingRequestIdToInactivityMonitor = (id) => {
  updatedAtTimestamp = null;
  $('#inactivity-alert').attr('data-booking-request-id', id);

  // Do not show the inactivity alert when the booking request is empty
  if (!id) {
    clearInterval(triggerActivityAlertCheckInterval);
    removeCountDownEventListeners();
    return;
  }

  if (!reservationChannel) {
    setReservationChannel(id);
  } else if (JSON.parse(reservationChannel['identifier']).id !== id) {
    consumer.subscriptions.remove(reservationChannel['identifier']);
    setReservationChannel(id);
  }

  touchBookingRequestIfActive(true);
};

window.appendBookingRequestIdToInactivityMonitor =
  appendBookingRequestIdToInactivityMonitor;

const keepActiveDuringPayment = () => {
  let currentUrl = window.location.href;
  if (currentUrl.endsWith('book/payment')) {
    listenerHandler();

    // Auto keep active every 30 seconds during payment process
    setTimeout(keepActiveDuringPayment, MS_IN_MINUTE / 2);
  }
};
