import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="conditional-fields"
export default class extends Controller {
  static targets = ['target'];
  static values = {
    active: String,
    initial: String,
  };

  connect() {
    this.setTarget(this.initialValue);
  }

  trigger(e) {
    this.setTarget(e.target.value);
  }

  setTarget(value) {
    if (this.activeValue === value) {
      this.targetTargets.forEach((target) => {
        target.disabled = false;
        target.hidden = false;
      });
    } else {
      this.targetTargets.forEach((target) => {
        target.disabled = true;
        target.hidden = true;
      });
    }
  }
}
