import { Controller } from '@hotwired/stimulus';

let selectedElement = null;
const isBefore = (a, b) => {
  if (a.parentNode == b.parentNode) {
    for (let cur = a; cur; cur = cur.previousSibling) {
      if (cur === b) {
        return true;
      }
    }
  }
  return false;
};

// Connects to data-controller="photo-drag"
export default class extends Controller {
  static targets = ['photoPreview'];

  initialize() {
    this.element.draggable = true;
    this.element.addEventListener('dragstart', this.dragStart);
    this.element.addEventListener('dragover', this.dragOver);
    this.element.addEventListener('drop', this.dragDrop);
    this.element.querySelectorAll('*').forEach(function (el) {
      el.draggable = false;
    });
    this.element.parentNode.classList.add('photo-preview');

    // hide draggable message if not supported
    const iOS =
      !!navigator.userAgent.match('iPhone OS') ||
      !!navigator.userAgent.match('iPad');
    const draggable = 'draggable' in document.createElement('div');
    if (!draggable || iOS) {
      const dragButtons = this.element.getElementsByClassName('drag-photo');
      [...dragButtons].forEach((dragButton) => {
        dragButton.classList.add('d-none');
      });
    }
  }

  dragStart(e) {
    e.stopPropagation();
    e.dataTransfer.effectAllowed = 'move';
    // Just need to know the id of the record we're moving
    e.dataTransfer.setData('text/plain', this.dataset.id);
    selectedElement = e.target.closest('.photo-preview');
  }

  dragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    let target = e.target;
    if (!target.classList.contains('.photo-preview')) {
      target = e.target.closest('.photo-preview');
    }
    if (isBefore(selectedElement, target)) {
      target.parentNode.insertBefore(selectedElement, target);
    } else {
      target.parentNode.insertBefore(selectedElement, target.nextSibling);
    }
  }

  dragDrop(e) {
    e.stopPropagation();
    const updateUrl = selectedElement.dataset.updatePositionUrl;
    const photos = [...document.querySelectorAll('.photo-preview')];
    const positions = [];
    photos.forEach((photo) => {
      positions.push(photo.dataset.photoId);
    });
    $.ajax({
      method: 'PATCH',
      url: updateUrl,
      data: { positions: positions },
    }).fail(function (error) {
      console.log(error.statusText);
    });
  }
}
