import { Controller } from '@hotwired/stimulus';
import * as Request from '@rails/request.js';

// Connects to data-controller="count-badge"
export default class extends Controller {
  static targets = ['count'];

  static values = {
    url: String,
    singularTitle: String,
    pluralTitle: String,
  };

  connect() {
    this.#updateCountElement();
  }

  #updateCountElement() {
    this.#fetchCount()
      .then((count) => {
        this.countTargets.forEach((target) => {
          target.innerText = count;

          // Show a descriptive title on mouse hover
          if (count == '1') {
            target.title = `${count} ${this.singularTitleValue}`;
          } else {
            target.title = `${count} ${this.pluralTitleValue}`;
          }
        });
      })
      .catch((error) => {
        console.error('Failed to fetch batch count', error);
      });
  }

  /**
   * Fetches the count {@link this.urlValue}.
   *
   * @return {Promise<number>} The count.
   */
  async #fetchCount() {
    if (!this.urlValue) {
      return 0;
    }

    const request = await Request.get(this.urlValue, {
      responseKind: 'json',
    });

    /**
     * @type {{ count: number }} response
     */
    const response = await request.response.json();
    return response.count || 0;
  }
}
