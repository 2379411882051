import $ from 'jquery';

/**
 * A stimulus controller mixin for jQuery select2 inputs.
 *
 * @param controller - The controller to attach this mixin into.
 */
export const useSelect2 = (controller) => {
  Object.assign(controller, {
    /**
     * Destroys a select2 input, and unbinds all of
     * the events we've manually bound.
     *
     * @param {Element} element - jQuery select2 input element to destroy.
     * @param {string} eventsToUnbind - All of the events we've manually bound to the {@link element}.
     */
    destroySelect2(element, ...eventsToUnbind) {
      if (!this.isSelect2(element)) {
        return;
      }

      $(element).select2('destroy');

      // Destroying will unbind the events that were automatically bound by the plugin.
      // Any explicit events that we've bound in our code, will need to be unbound manually.
      if (eventsToUnbind.length) {
        $(element).off(eventsToUnbind.join(' '));
      }
    },

    /**
     * Returns whether or not the given {@link element} has been initialized as a jQuery Select2 element.
     *
     * @param {Element} element - Element to check.
     * @returns {boolean} - `true` if is select2 or null, otherwise `false`.
     */
    isSelect2(element) {
      if (!element) {
        return false;
      }

      return element.classList.contains('select2-hidden-accessible');
    },
  });
};
