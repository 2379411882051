import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

// Connects to data-controller="routine--context-selection"
export default class extends Controller {
  contextChanged() {
    Rails.fire(window, 'routine:context:changed');
  }

  submit(e) {
    Rails.fire(e.target.form, 'submit');
  }
}
