import $ from 'jquery';
import _ from 'lodash';
import { Controller } from '@hotwired/stimulus';
import * as Request from '@rails/request.js';

const debounce = require('debounce');

export default class extends Controller {
  static targets = [
    'input',
    'button',
    'checkboxGroup',
    'checkboxGroupInput',
    'dropdown',
    'dropdownItem',
  ];
  static values = {
    itemList: String,
    searchUrl: String,
  };

  initialize() {
    this.setupEvents();
    this.debouncedSearch = debounce(this.#search, 500);
  }

  setupEvents() {
    $(this.inputTarget).on('keydown', (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });

    this.inputTarget.addEventListener('input', () => {
      this.debouncedSearch();
    });

    this.buttonTarget.addEventListener('click', () => {
      this.debouncedSearch();
    });

    // Handle Status filters click / change
    const checkboxItemElements = this.checkboxGroupTarget.querySelectorAll(
      'input[type="checkbox"].form-check-input'
    );
    checkboxItemElements.forEach((element) => {
      element.addEventListener('click', (event) => {
        this.#updateCheckboxCombination.bind(this)(event);
        this.debouncedSearch();
      });
    });
  }

  /**
   * Add click event to 'Set all' dropdown items to change
   * the acceptance status for all items in the list.
   */
  dropdownItemTargetConnected(target) {
    const acceptanceStatus = target.dataset.acceptance;
    target.addEventListener('click', () => {
      this.#setAllApprovals.bind(this)(acceptanceStatus);
    });
  }

  async #search() {
    const query = this.inputTarget.value.toLowerCase();

    const statusInputs = this.checkboxGroupTarget.querySelectorAll(
      'input[type=checkbox]:checked'
    );
    const checkedStatus = _.map(statusInputs, 'value').join(' ');

    // TODO (improv): make this search the default for the rest of types by adding
    // `search_url` when rendering `shared/components/approvals_table_search`
    // - Currently it only applies to Activity type.

    if (this.searchUrlValue) {
      const url = new URL(this.searchUrlValue);
      url.searchParams.set('activity_search[q]', query);
      url.searchParams.set('activity_search[status]', checkedStatus);

      await Request.get(url.toString(), {
        responseKind: 'turbo-stream',
      });

      return;
    }

    // TODO (improv): Deprecate this original JS search when previous TODO is done.
    const statuses = $(this.checkboxGroupTarget)
      .find('input[type=checkbox]:checked')
      .map(function () {
        return $(this).val();
      })
      .get();

    const $itemList = $(this.itemListValue);
    $itemList.each(function (index, item) {
      const $item = $(item);

      // Check all the td elements for matching text
      const findText = function (item, query) {
        index = _.findIndex(item.find('td'), function (cell) {
          const text = $(cell).text().toLowerCase();
          return text.includes(query);
        });

        if (index >= 0) return true;
      };

      const matches = function (item) {
        // current status of the item
        const currentStatus = item.find('input.btn-check:checked').val();

        // Check the status matches, or true for all...
        const matchStatus =
          statuses.includes(currentStatus) || statuses.includes('all');

        const match = findText(item, query) && matchStatus;

        if (match) return true;

        return false;
      };

      if (matches($item)) {
        // If the search is empty and we've checked all then we need to collapse
        // the accordion view again.
        if (_.isEmpty(query) && statuses.includes('all')) {
          $item.show();
          $item.removeClass('show');
        } else {
          // Make sure to add show class so this also works for our accordion view
          $item.addClass('show');
          $item.show();
        }
      } else {
        $item.removeClass('show');
        $item.hide();
      }
    });
  }

  #updateCheckboxCombination(e) {
    /**
     * The idea:
     *   - Click to uncheck any:
     *     - clicked on === 'all':
     *       - maintain selection on 'all'.
     *     - clicked on !== 'all':
     *       - if other options except for 'all' are unchecked:
     *         - select 'all'.
     *       - otherwise:
     *         - do nothing.
     *   - Clicks to check any:
     *     - clicked on === 'all'.
     *       - unselect all others.
     *     - clicked on !== 'all':
     *       - unselect all.
     */

    const { checked, value } = e.target;
    const validatingAll = value === 'all';

    // Unchecked
    if (!checked) {
      if (validatingAll) {
        // Maintain selection on "all"
        this.#getStatusInputTargetByValue('all').checked = true;
      } else {
        // Check "all" by default if all have been unchecked
        if (!this.#getCheckedStatusValues().length) {
          const target = this.#getStatusInputTargetByValue('all');
          target.checked = true;
        }
      }
      return;
    }

    // Checked
    if (validatingAll) {
      // We're checking the "all" box, so uncheck all others
      const otherTargets = _.filter(
        this.checkboxGroupInputTargets,
        (target) => target.value !== 'all'
      );
      otherTargets.forEach((target) => {
        target.checked = false;
      });
    } else {
      // We're checking any other target, so uncheck "all"
      const target = this.#getStatusInputTargetByValue('all');
      target.checked = false;
    }
  }

  /**
   * Retrieves one checkboxGroupInputTarget element by the status {@link value}.
   * @param {string} statusValue
   * @return {HTMLInputElement | undefined}
   */
  #getStatusInputTargetByValue(statusValue) {
    return _.find(
      this.checkboxGroupInputTargets,
      (target) => target.value === statusValue
    );
  }

  /**
   * Retrieves the status values of all of the checked statuses. Empty array if none are checked.
   * @return {Array<string>}
   */
  #getCheckedStatusValues() {
    const checkedTargets = _.filter(
      this.checkboxGroupInputTargets,
      (target) => target.checked
    );
    return _.map(checkedTargets, 'value');
  }

  #setAllApprovals(acceptanceStatus) {
    const rememberAcceptanceStatusSummary =
      this.#rememberAcceptanceStatusSummary.bind(this);

    // We only want to apply the change to those that are visible in case the user is filtering.
    $(this.itemListValue)
      .filter(':visible')
      .each((index, item) => {
        const inputElement = item.querySelector(
          `input.btn-check[value="${acceptanceStatus}"]`
        );
        if (inputElement && !inputElement.disabled) {
          inputElement.checked = true;

          const recordId = inputElement.dataset.recordId;
          rememberAcceptanceStatusSummary(acceptanceStatus, recordId);
        }
      });
  }

  #rememberAcceptanceStatusSummary(acceptanceStatus, recordId) {
    this.dispatch('rememberStatusChange', {
      prefix: 'acceptance-status-summary',
      detail: { acceptanceStatus, recordId },
    });
  }
}
