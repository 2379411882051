import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import { useSelect2 } from './mixins/useSelect2';

// Connects to data-controller="rebook-select"
export default class extends Controller {
  static targets = ['input', 'parent', 'form'];

  static values = {
    searchPath: String,
  };

  connect() {
    this.initSelectInput();
  }

  initialize() {
    useSelect2(this);
  }

  initSelectInput() {
    $(this.inputTarget).select2({
      ajax: {
        url: this.searchPathValue,
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            q: params.term,
            page: params.page,
          };
        },
        processResults: function (response) {
          response.data.forEach(
            (resource) =>
              (resource.text =
                resource.attributes.text || resource.attributes.name)
          );

          return {
            results: response.data,
            pagination: {
              more: response.links?.next,
            },
          };
        },
      },
      dropdownParent: this.parentTarget,
    });
  }
}
