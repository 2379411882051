import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="booking-action-manual-selection"
export default class extends Controller {
  static targets = ['toggle', 'frameContainer', 'instanceToggle', 'submit'];

  connect() {
    this.refreshTotal();
    this.setInitialStatus();
    this.setToggleListener();
    this.element.addEventListener('turbo:frame-render', this.loadEvent);
  }

  setInitialStatus = () => {
    this.toggleTargets.forEach((toggle) => {
      if (toggle.checked && toggle.value === 'subset') {
        this.frameContainerTarget.classList.add('show');
      }
    });
  };

  setInstanceToggleListener = () => {
    this.instanceToggleTargets.forEach((toggle) => {
      toggle.addEventListener('change', () => {
        this.setSubmitStatus();
      });
    });
  };

  setToggleListener = () => {
    this.toggleTargets.forEach((toggle) => {
      toggle.addEventListener('change', (event) => {
        this.toggle(event);
      });
    });
  };

  toggle = (e) => {
    if (e.target.checked && e.target.value === 'subset') {
      this.frameContainerTarget.classList.add('show');
    } else {
      this.frameContainerTarget.classList.remove('show');
      this.setSubmitStatus(true);
    }
  };

  loadEvent = (e) => {
    if (e.target.id === 'manual-selection') {
      this.setInstanceToggleListener();
      this.refreshTotal();
    }
  };

  refreshTotal = () => {
    const loadEvent = new CustomEvent('booking-action:refresh-total');
    window.dispatchEvent(loadEvent);
  };

  toggleAll = (e) => {
    const checked = e.target.checked;
    this.instanceToggleTargets.forEach((toggle) => {
      toggle.checked = checked;
    });
    this.refreshTotal();
    this.setSubmitStatus();
  };

  setSubmitStatus = (enable = false) => {
    const anyChecked = this.instanceToggleTargets.some(
      (input) => input.checked
    );
    if (anyChecked || enable) {
      this.submitTarget.removeAttribute('disabled');
    } else {
      this.submitTarget.setAttribute('disabled', '');
    }
  };
}
