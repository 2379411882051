import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="my-money-csv"
export default class extends Controller {
  static targets = ['link'];

  updateLink(event) {
    const { detail } = event;
    const searchParams = new URLSearchParams();
    const financialTransactionsSearch = detail['financial_transactions_search'];
    for (const key in financialTransactionsSearch) {
      searchParams.set(
        `financial_transactions_search[${key}]`,
        financialTransactionsSearch[key]
      );
    }

    const existingParams = new URLSearchParams(this.linkTarget.search);
    if (existingParams.get('type')) {
      searchParams.set('type', existingParams.get('type'));
    }

    const baseUrl = this.linkTarget.pathname;
    this.linkTarget.href = `${baseUrl}?${searchParams.toString()}`;
  }
}
