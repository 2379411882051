import Rails from '@rails/ujs';
import { Controller } from '@hotwired/stimulus';

const debounce = require('debounce');

export default class extends Controller {
  static targets = ['form'];

  static values = {
    wait: { type: Number, default: 350 },
  };

  initialize() {
    this._debouncedSubmit = debounce(this.submit, this.waitValue);
  }

  submit() {
    Rails.fire(this.formTarget, 'submit');
  }

  debouncedSubmit() {
    this._debouncedSubmit();
  }
}
