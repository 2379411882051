import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="loading-overlay"
export default class extends Controller {
  static targets = ['form'];

  initialize() {
    if (this.hasFormTarget) {
      this.formTarget.addEventListener('turbo:submit-start', () =>
        this.showOverlay()
      );
      this.formTarget.addEventListener('turbo:submit-end', () =>
        this.hideOverlay()
      );
    }
  }

  showOverlay() {
    document.getElementById('loading-overlay').classList.add('active');
  }

  hideOverlay() {
    document.getElementById('loading-overlay').classList.remove('active');
  }
}
