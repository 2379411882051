import CalendarController from './calendar_controller';
import { Turbo } from '@hotwired/turbo-rails';
import $ from 'jquery';
import _ from 'lodash';

// Connects to data-controller="bookings-calendar"
export default class extends CalendarController {
  static values = {
    bookingsUrl: String,
    facilitiesUrl: String,
    facilityColours: Array,
  };

  static targets = [
    'venueSearch',
    'btnVenueSearch',
    'facilityInput',
    'statusInput',
  ];

  initialize() {
    this.setCalendarOptions();
    this.initCalendar();
    super.getSchedulesRequest(this.bookingsUrlValue);
    this.setupVenueSearchEvents();
  }

  setCalendarOptions() {
    this.calendarOptionsValue = {
      ...this.calendarOptionsValue,
      ...this.calendarOptions,
    };
  }

  setDayMenuStatus(day) {
    this.daySelectorTargets.forEach((d) => {
      const { bookingsCalendarDayParam } = d.dataset;
      if (bookingsCalendarDayParam == day) {
        d.classList.add('btn-secondary');
        d.classList.remove('btn-outline-secondary');
      } else {
        d.classList.add('btn-outline-secondary');
        d.classList.remove('btn-secondary');
      }
    });
  }

  setupVenueSearchEvents() {
    $(this.venueSearchTarget).on('change', () => {
      super.getSchedulesRequest(this.bookingsUrlValue, this.getVenueIdParams());
      this.getFacilityList();
    });

    this.btnVenueSearchTarget.addEventListener('click', () => {
      this.getSchedulesRequest();
    });
  }

  // Override any calls that the calendar parent class makes to getSchedulesRequest
  // so that we can pass through additional params
  getSchedulesRequest() {
    const params = {
      ...this.getVenueIdParams(),
      ...this.getFacilityIdsParams(),
      ...this.getStatusParams(),
    };

    super.getSchedulesRequest(this.bookingsUrlValue, params);
  }

  getFacilityList() {
    const params = {
      ...this.getVenueIdParams(),
      ...this.getFacilityIdsParams(),
    };

    $.ajax({
      method: 'GET',
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
      url: this.facilitiesUrlValue,
      data: params,
    })
      .done(function (html) {
        Turbo.renderStreamMessage(html);
      })
      .fail(function (error) {
        console.log(error.statusText);
      });
  }

  getVenueIdParams() {
    return {
      venue_id: this.venueSearchTarget.value,
    };
  }

  getFacilityIdsParams() {
    const selectedIds = $(this.facilityInputTargets)
      .filter(':checked')
      .get()
      .map((input) => input.value);

    return {
      facility_ids: selectedIds,
    };
  }

  getStatusParams() {
    const selectedStatuses = $(this.statusInputTargets)
      .filter(':checked')
      .get()
      .map((input) => input.value);

    return {
      selected_statuses: selectedStatuses,
    };
  }

  getSchedulesRequestsResponse(res) {
    let schedules = [];
    if (res['bookings']) {
      this.loadBookings(res['bookings']['data'], schedules);
    }

    this.calendar.clear();
    this.calendar.createSchedules([...schedules]);
  }

  toggleFacility() {
    this.getSchedulesRequest();
  }

  toggleAll() {
    this.getSchedulesRequest();
  }

  toggleStatus() {
    this.getSchedulesRequest();
  }

  loadBookings(bookings, schedules) {
    const bookingsByFacility = _.groupBy(bookings, (booking) => {
      return booking.relationships.facility.data.id;
    });

    _.forEach(
      bookingsByFacility,
      function (bookingGroup) {
        _.forEach(
          bookingGroup,
          function (booking) {
            schedules.push(this.bookingToCalendarSchedule(booking));
          }.bind(this)
        );
      }.bind(this)
    );
  }

  // Dynamically creating calendars doesn't seem to work and all entries
  // will be created with the tui default colour. But, we don't need individual
  // calendars or schedules so we can just set the bgColor directly per instance.
  bookingToCalendarSchedule(instance) {
    const facilityId = instance.relationships.facility.data.id;
    const facilityColour = this.lookupFacilityColour(facilityId);
    let bri = instance.attributes;
    bri.calendarId = 'default';
    bri.title = bri.description;
    bri.category = 'time';
    bri.start = this.stripTimeZone(bri['start-time']);
    bri.end = this.stripTimeZone(bri['end-time']);
    bri.isReadOnly = true;
    bri.color = bri['text-colour'];
    bri.bgColor = bri['cal-colour'];
    bri.borderColor = bri.bgColor;
    bri.customStyle = `margin-right: 8px !important; border-left: 5px solid ${
      facilityColour.bg_color
    }; padding-left: 2px; text-decoration: ${
      bri['status'] === 'suspended' ? 'line-through' : 'none'
    }`;
    bri.raw = {
      bookingsPath: bri['bookings-path'],
    };
    return bri;
  }

  lookupFacilityColour(facilityId) {
    const obj = _.find(this.facilityColoursValue, ['facility_id', facilityId]);
    return _.isNil(obj) ? { bg_color: '#cefddc', color: '#00' } : obj;
  }

  clickScheduleEvent(e) {
    const path = e.schedule.raw.bookingsPath;
    window.open(path, '_blank');
  }

  calendars = [
    {
      id: 'default',
      name: 'default',
    },
  ];

  calendarOptions = {
    calendars: this.calendars,
  };
}
