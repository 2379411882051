import $ from 'jquery';
import { Controller } from '@hotwired/stimulus';

// Controller to toggle between two container views using a button (with an icon span)
// e.g. between the search list view and the map view
export default class extends Controller {
  static targets = ['button'];
  static values = {
    primaryElement: String,
    primaryIcon: String,
    primaryText: String,
    alternateElement: String,
    alternateIcon: String,
    alternateText: String,
    cookieName: String,
    cookieToggle: String,
  };

  initialize() {
    $(this.buttonTarget).click(() => {
      this.toggle();
    });
  }

  toggle() {
    const _primary = $(this.primaryElementValue);
    const _alternate = $(this.alternateElementValue);
    const _toggle = $(this.buttonTarget);
    const hideClass = 'd-none';

    if (_primary.is(':visible')) {
      _primary.addClass(hideClass);
      _alternate.removeClass(hideClass);
      _toggle
        .find('span')
        .html(`\n${this.alternateTextValue}\n`)
        .removeClass(this.primaryIconValue)
        .addClass(this.alternateIconValue);

      // Remember the toggle state
      document.cookie = `${this.cookieNameValue}=${this.cookieToggleValue};`;

      // Custom event to listen for in the DOM so we can fire an event in another controller when tied to
      // an action: e.g.  action: 'togglePrimary@window->map#updateBounds'
      const event = new CustomEvent('togglePrimary');
      window.dispatchEvent(event);
    } else {
      _alternate.addClass(hideClass);
      _primary.removeClass(hideClass);
      _toggle
        .find('span')
        .html(`\n${this.primaryTextValue}\n`)
        .removeClass(this.alternateIconValue)
        .addClass(this.primaryIconValue);

      document.cookie = `${this.cookieNameValue}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;

      const event = new CustomEvent('toggleAlternate');
      window.dispatchEvent(event);
    }
  }
}
