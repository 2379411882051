import { Controller } from '@hotwired/stimulus';

export interface ElementEnabledMixin {
  setElementEnabled(element?: any, isEnabled?: boolean): void;
}

/**
 * A stimulus controller mixin to enable / disable a target element.
 * Exposes `#setElementEnabled(element, isEnabled: boolean = true)`
 *
 * @param controller - Stimulus controller to attach this mixin.
 */
export const useElementEnabled = <
  InputController extends Controller,
  ReturnController = InputController & ElementEnabledMixin
>(controller): ReturnController => {
  Object.assign(controller, {
    /**
     * Whether or not to set the {@link element} to be enabled.
     *
     * @param element - The element to change its enabled state. Can be nullish.
     * @param isEnabled - True (default) to make the element enabled, otherwise disable it.
     */
    setElementEnabled(element?: any, isEnabled: boolean = true) {
      if (!element) {
        // Do nothing if element is nullish
        return;
      }

      element.disabled = !isEnabled;

      if (isEnabled) {
        element.classList.remove('disabled');
      } else {
        element.classList.add('disabled');
      }
    },
  });

  return controller as ReturnController;
};
