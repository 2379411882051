import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="toggle-all"
export default class extends Controller {
  static targets = ['toggle'];

  toggleAll(e) {
    const { checked } = e.target;
    this.toggleTargets.forEach((target) => {
      if (target.checked !== checked) {
        target.checked = checked;
      }
    });
  }
}
