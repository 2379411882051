import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="booking-details"
export default class extends Controller {
  static targets = ['frame'];
  static values = {
    facility: Number,
  };

  reload(event) {
    const { facility } = event.detail;
    if (this.facilityValue === facility) {
      this.frameTarget.reload();
    }
  }
}
