import $ from 'jquery';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];
  static values = {
    path: String,
    tags: { type: Boolean, default: true },
    closeOnSelect: { type: Boolean, default: false },
    parent: { type: String, default: '' },
  };

  initialize() {
    const config = {
      ajax: {
        url: this.pathValue,
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            q: params.term,
            page: params.page,
          };
        },
        processResults: function (response) {
          response.data.forEach(
            (resource) =>
              (resource.text =
                resource.attributes.text || resource.attributes.name)
          );

          return {
            results: response.data,
            pagination: {
              more: response.links?.next,
            },
          };
        },
      },
      createTag: function (params) {
        return {
          id: params.term,
          text: params.term,
          isTag: true,
        };
      },
      placeholder: $(this.inputTarget).attr('placeholder'),
      tags: this.tagsValue,
      closeOnSelect: this.closeOnSelectValue,
      width: 'style',
    };
    if (this.parentValue) {
      config['dropdownParent'] = $(this.parentValue);
    }
    $(this.inputTarget).select2(config);

    $(this.inputTarget).on(
      'select2:select select2:unselect select2:clear',
      function () {
        const event = new Event('change', { bubbles: true });
        this.dispatchEvent(event);
      }
    );
  }
}
