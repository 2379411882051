import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="check-box-as-radio"
export default class extends Controller {
  checkBoxes = [];

  connect() {
    this.checkBoxes = this.element.querySelectorAll('input[type="checkbox"]');
    this.checkBoxes.forEach((cb) =>
      cb.addEventListener('change', this.checkBoxChange.bind(this))
    );
  }

  checkBoxChange(event) {
    this.checkBoxes.forEach((cb) => {
      if (event.target !== cb) {
        cb.checked = !event.target.checked;
      }
    });
  }
}
