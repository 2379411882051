import CalendarController from './calendar_controller';
import $ from 'jquery';
import moment from 'moment/moment';
import { createPopper } from '@popperjs/core';
import _ from 'lodash';
import { Turbo } from '@hotwired/turbo-rails';

let lastEvent = null;

// Connects to data-controller="search-book-calendar"
export default class extends CalendarController {
  static values = {
    bookingsUrl: String,
    allowPastBookings: Boolean,
    impersonating: Boolean,
    operatorBookingsEditableLimit: Number,
    impersonationModalUrl: String,
    continueAsNewCustomer: Boolean,
    currentUserOperator: Boolean,
  };

  static targets = ['rightClickTooltip', 'tooltipDestroyBookingForm'];

  initialize() {
    this.setCalendarOptions();
    this.initCalendar();
    this.getSchedulesRequest(this.bookingsUrlValue);
  }

  bookingCreated() {
    this.getSchedulesRequest();
  }

  bookingUpdated() {
    this.getSchedulesRequest();
  }

  setCalendarOptions() {
    this.calendarOptionsValue = {
      ...this.calendarOptionsValue,
      ...this.calendarOptions,
    };
  }

  getSchedulesRequestsResponse(res) {
    if (res['bookings']) this.loadBookingRequests(res['bookings']);
    if (res['closed_hours']) this.loadClosedHours(res['closed_hours']);
  }

  clickScheduleEvent(e) {
    if (e.schedule.calendarId === 'requested') {
      this.renderShowBookingModal(e);
    } else if (
      e.schedule.calendarId === 'currentRelatedRequests' ||
      e.schedule.calendarId === 'currentRelatedBookings'
    ) {
      window.location.href = e.schedule.raw.relatedFacilityPath;
    } else {
      const path = e.schedule.raw.bookingsPath;
      window.open(path, '_blank');
    }
  }

  eventHasClosedHoursConflict(e) {
    const closed_hours = _.filter(
      this.getEventsForCalendarId('closedHours'),
      function (closed) {
        return (
          (closed.start >= e.start && closed.end <= e.end) ||
          (e.start > closed.start && e.start < closed.end) ||
          (e.end > closed.start && e.end < closed.end)
        );
      }
    );

    return !_.isEmpty(closed_hours);
  }

  beforeCreateScheduleEvent(e) {
    lastEvent = e;
    const isImpersonating = this.impersonatingValue;
    const allowPastBookings = this.allowPastBookingsValue;
    const isOperator = this.currentUserOperatorValue;
    const impersonationModalUrl = this.impersonationModalUrlValue;

    const date28Days = moment()
      .subtract(this.operatorBookingsEditableLimitValue, 'seconds')
      .startOf('day');
    const dateDefault = moment().startOf('day');

    const minBookingTime = allowPastBookings
      ? isImpersonating
        ? null
        : date28Days
      : dateDefault;

    if (
      isOperator &&
      !this.continueAsNewCustomerValue &&
      e.start.toDate().getTime() >= minBookingTime
    ) {
      const data = this.dateParamsFromEvent(e);
      $.get(impersonationModalUrl, data)
        .done(function (html) {
          Turbo.renderStreamMessage(html);
        })
        .fail(function (error) {
          console.log(error.statusText);
        });
      e.guide.clearGuideElement();
      return;
    }

    if (this.eventHasClosedHoursConflict(e)) {
      e.guide.clearGuideElement();
      return;
    }

    if (e.start.toDate().getTime() >= minBookingTime) {
      this.dispatchEvent('calendar:modal:open', e);
      window.displayCreateBookingModal(e);
    } else {
      e.guide.clearGuideElement();
    }
  }

  displayCreateBookingModal() {
    window.dispatchEvent(new CustomEvent('mobile:modal:open'));
    window.displayCreateBookingModal();
  }

  beforeUpdateScheduleEvent(e) {
    if (this.eventHasClosedHoursConflict(e)) return;

    this.renderShowBookingModal(e);
  }

  rightClickScheduleEvent(e) {
    if (e.schedule.calendarId !== 'requested') return;
    this.renderRightClickPopover(e);
    e.event.target.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    });
  }

  renderShowBookingModal(e) {
    const data = this.dateParamsFromEvent(e);
    $.get(e.schedule.raw.showPath, data);
  }

  dateParamsFromEvent(e) {
    const { startDate, endDate } = e.start
      ? this.startAndEndDateFromCalendarEvent(e)
      : [null, null];
    const data = {
      start_time: startDate,
      end_time: endDate,
    };
    return data;
  }

  continueAsNewCustomer() {
    this.continueAsNewCustomerValue = true;
    this.beforeCreateScheduleEvent(lastEvent);
  }

  renderRightClickPopover(e) {
    const trigger = e.event.target;
    const { deletePath } = e.schedule.raw;
    this.rightClickTooltipTarget.classList.remove('d-none');
    this.tooltipDestroyBookingFormTarget.setAttribute('action', deletePath);
    createPopper(trigger, this.rightClickTooltipTarget);
    const hide = () => {
      this.rightClickTooltipTarget.classList.add('d-none');
      document.removeEventListener('click', hide);
    };
    document.addEventListener('click', hide);
  }

  dispatchEvent(name, calendarEvent) {
    const event = new CustomEvent(name, {
      detail: this.startAndEndDateFromCalendarEvent(calendarEvent),
    });
    window.dispatchEvent(event);
  }

  loadClosedHours(closed_hours) {
    if (this.calendar.getViewName() == 'month') return;

    const closedSchedule = this.closedHoursToCalendarSchedule(
      closed_hours,
      'closedHours'
    );

    this.calendar.createSchedules(closedSchedule);
  }

  loadBookingRequests(bookings) {
    const myRequests = bookings['my_requests']['data'];
    const myBookings = bookings['my_bookings']['data'];
    const myRelatedRequests = bookings['my_related_requests']['data'];
    const myRelatedBookings = bookings['my_related_bookings']['data'];
    const otherRequests = bookings['other_requests']['data'];
    const otherBookings = bookings['other_bookings']['data'];
    const operatorBookings = bookings['operator_bookings']['data'];

    const myRequestsSchedule = this.bookingsToCalendarSchedule({
      instances: myRequests,
    });

    const myBookingsSchedule = this.bookingsToCalendarSchedule({
      instances: myBookings,
      readOnly: true,
    });

    const myRelatedRequestsSchedule = this.bookingsToCalendarSchedule({
      instances: myRelatedRequests,
      calendarId: 'currentRelatedRequests',
      title: 'Related Request',
      readOnly: true,
    });

    const myRelatedBookingsSchedule = this.bookingsToCalendarSchedule({
      instances: myRelatedBookings,
      calendarId: 'currentRelatedBookings',
      title: 'Related Booking',
      readOnly: true,
    });

    const otherRequestsSchedule = this.bookingsToCalendarSchedule({
      instances: otherRequests,
      calendarId: 'other',
      title: 'Unavailable',
      readOnly: true,
    });

    const otherBookingsSchedule = this.bookingsToCalendarSchedule({
      instances: otherBookings,
      calendarId: 'other',
      title: 'Unavailable',
      readOnly: true,
    });

    const operatorBookingsSchedule = this.operatorBookingsToCalendarSchedule(
      operatorBookings,
      'operatorBookings'
    );

    this.calendar.clear();

    this.calendar.createSchedules([
      ...myRequestsSchedule,
      ...myBookingsSchedule,
      ...myRelatedRequestsSchedule,
      ...myRelatedBookingsSchedule,
      ...otherRequestsSchedule,
      ...otherBookingsSchedule,
      ...operatorBookingsSchedule,
    ]);
  }

  closedHoursToCalendarSchedule(closedHours, calendarId) {
    return closedHours.map((data) => {
      return {
        calendarId: calendarId,
        category: 'time',
        start: this.stripTimeZone(data.start_time),
        end: this.stripTimeZone(data.end_time),
        isReadOnly: true,
        customStyle: 'cursor:default',
      };
    });
  }

  operatorBookingsToCalendarSchedule(operatorBookings, calendarId) {
    return operatorBookings.map((data) => {
      let operatorBooking = data.attributes;
      return {
        calendarId: calendarId,
        title: operatorBooking.description,
        category: 'time',
        start: this.stripTimeZone(operatorBooking['start-time']),
        end: this.stripTimeZone(operatorBooking['end-time']),
        isReadOnly: true,
        customStyle: 'cursor:default',
      };
    });
  }
  bookingsToCalendarSchedule({
    instances = instances,
    calendarId,
    title,
    readOnly = null,
  }) {
    return instances.map((data) => {
      let bri = data.attributes;
      bri.calendarId = calendarId ?? bri['status'];
      bri.title = title ?? bri['title'];
      bri.category = 'time';
      bri.start = this.stripTimeZone(bri['start-time']);
      bri.end = this.stripTimeZone(bri['end-time']);
      bri.isReadOnly = readOnly === null ? bri['is-read-only'] : readOnly;
      bri.customStyle = `margin-right: 8px !important; padding-left: 2px; text-decoration: ${
        bri['status'] === 'suspended' && !calendarId ? 'line-through' : 'none'
      }`;
      bri.raw = {
        showPath: bri['show-path'],
        deletePath: bri['delete-path'],
        relatedFacilityPath: bri['related-facility-path'],
        bookingsPath: bri['bookings-path'],
      };
      return bri;
    });
  }

  calendars = [
    {
      id: 'closedHours',
      name: 'Closed Hours',
      bgColor: '#c2c6cd80',
      borderColor: '#c2c6cd80',
    },
    {
      id: 'requested',
      name: 'My booking requests',
      bgColor: '#cefddc',
      dragBgColor: '#bbf4cb',
      borderColor: '#38ff6c',
    },
    {
      id: 'pending_approval',
      name: 'My pending approval requests',
      bgColor: '#b1e5fa',
      dragBgColor: '#b1e5fa',
      borderColor: '#37bffd',
    },
    {
      id: 'payment_requested',
      name: 'My payment requested requests',
      bgColor: '#e5780b',
      dragBgColor: '#e5780b',
      borderColor: '#ab5908',
      color: '#ffffff',
    },
    {
      id: 'suspended',
      name: 'My suspended bookings',
      bgColor: '#ff6b6b',
      dragBgColor: '#ff6b6b',
      borderColor: '#ff3737',
    },
    {
      id: 'currentRelatedRequests',
      name: 'My related booking requests',
      bgColor: '#cefddc',
      dragBgColor: '#bbf4cb',
      borderColor: '#38ff6c',
    },
    {
      id: 'booked',
      name: 'My bookings',
      bgColor: '#82ffa9',
      dragBgColor: '#2d8f48',
      borderColor: '#29bb4f',
    },
    {
      id: 'paid',
      name: 'My bookings',
      bgColor: '#1a7f37',
      dragBgColor: '#285430',
      borderColor: '#285430',
    },
    {
      id: 'unpaid',
      name: 'My bookings',
      bgColor: '#dc3545',
      dragBgColor: '#842029',
      borderColor: '#842029',
    },
    {
      id: 'currentRelatedBookings',
      name: 'My related bookings',
      bgColor: '#82ffa9',
      dragBgColor: '#2d8f48',
      borderColor: '#29bb4f',
    },
    {
      id: 'other',
      name: 'Other bookings',
      bgColor: '#e1e1e1',
      dragBgColor: '#e1e1e1',
      borderColor: '#5b5b5b',
    },
    {
      id: 'operatorBookings',
      name: 'Operator bookings',
      bgColor: '#e1e1e1',
      dragBgColor: '#e1e1e1',
      borderColor: '#5b5b5b',
    },
  ];

  calendarOptions = {
    calendars: this.calendars,
  };
}
