import SearchController from './search_controller.js';
import { useSort } from './mixins/useSort';

export default class extends SearchController {
  static targets = ['sortOption', 'sortOptionInput', 'sortDirectionInput'];

  connect() {
    super.connect();

    useSort(this);
    this.initSort();
  }

  onSuccess(e) {
    super.onSuccess(e);
    this.initSort();
  }
}
