import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="acceptance-status"
export default class extends Controller {
  static targets = ['form'];

  submitEnd(event) {
    if (!event.detail.success) {
      window.scrollTo(0, 0);
      this.formTarget.reset();
    }
  }
}
