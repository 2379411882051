import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="disable-on-confirm"
export default class extends Controller {
  connect() {
    this.disableElement();
  }

  disableElement = () => {
    $(this.element).on('confirm:complete ', (event) => {
      const confirmed = event.detail[0];
      if (confirmed) {
        this.element.disabled = true;
      }
    });
  };
}
