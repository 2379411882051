import { Controller } from '@hotwired/stimulus';
import _ from 'lodash-contrib';
import { useElementEnabled } from '../mixins/useElementEnabled';
import { formatAsMoneyString, parseFormattedMoney } from '../helpers/money';

// Connects to data-controller="bookings-approvals-form"
export default class extends Controller {
  static targets = [
    'reasonInput',
    'submitButton',
    'editInstancePriceButton',
    'customerPriceColumnText',
    'totalCustomerPriceForBookingText',
    'totalCustomerPriceForFacilityText',
    'hireChargeInput',
    'status',
    'approveButton',
    'rejectButton',
  ];

  connect() {
    useElementEnabled(this);

    this.#recalculateTotalCustomerPriceForBooking();
    this.#initButtonStyling();
  }

  editInstancePriceButtonTargetConnected(target) {
    target.addEventListener('click', (event) => {
      const facilityId = event.currentTarget.dataset.facilityId;
      const bookingInstanceId = event.currentTarget.dataset.bookingInstanceId;
      const bookingLinkId = event.currentTarget.dataset.bookingLinkId;
      const originalHireCharge = event.currentTarget.dataset.originalHireCharge;
      const latestHireCharge =
        this.#findHireChargeInputTargetForBookingInstance(
          bookingInstanceId
        )?.value;

      this.#showEditModal({
        facilityId,
        bookingInstanceId,
        bookingLinkId,
        originalHireCharge,
        latestHireCharge,
      });
    });
  }

  /**
   * @param {{
   *  decision: 'approve' | 'approve_all' | 'reject' | 'reject_all',
   *  bookingInstanceId: string,
   *  facilityId: string,
   *  reason: string,
   * }} detail
   */
  onReasonConfirm({ detail }) {
    const decision = detail.decision;
    const bookingInstanceId = detail.bookingInstanceId;
    const facilityId = detail.facilityId;
    const reason = detail.reason;

    switch (decision) {
      case 'approve':
      case 'reject':
        this.#decideForBookingInstance({
          bookingInstanceId,
          facilityId,
          decision,
          reason,
        });
        break;

      case 'approve_all':
      case 'reject_all':
        this.#decideForAllFacilityBookingInstances({
          facilityId,
          decision,
          reason,
        });
        break;
    }

    this.#handleAllBookingInstancesDecided();
  }

  /**
   * @param {{
   *  facilityId: string,
   *  bookingInstanceId: string,
   *  bookingLinkId: string,
   *  hireCharge: string,
   *  customerPrice: string,
   *  formattedCustomerPrice: string
   *  applyToAll: boolean
   * }} detail
   */
  onEditSave({ detail }) {
    const facilityId = detail.facilityId;
    const bookingInstanceId = detail.bookingInstanceId;
    const bookingLinkId = detail.bookingLinkId;
    const hireCharge = detail.hireCharge;
    const customerPrice = detail.customerPrice;
    const applyToAll = detail.applyToAll;

    if (applyToAll) {
      const hireChargeInputs =
        this.#findHireChargeInputTargetsForBookingLink(bookingLinkId);
      hireChargeInputs.forEach((hireChargeInput) => {
        hireChargeInput.value = hireCharge;
      });
      const customerPriceColumns =
        this.#findCustomerPriceColumnTextTargetForBookingLink(bookingLinkId);
      customerPriceColumns.forEach((col) => {
        col.innerText = formatAsMoneyString(customerPrice);
      });
    } else {
      const hireChargeInput =
        this.#findHireChargeInputTargetForBookingInstance(bookingInstanceId);
      hireChargeInput.value = hireCharge;
      const customerPriceColumn =
        this.#findCustomerPriceColumnTextTargetForBookingInstance(
          bookingInstanceId
        );
      customerPriceColumn.innerText = formatAsMoneyString(customerPrice);
    }
    // Recalculate the prices
    this.#recalculateTotalCustomerPriceForFacility(facilityId);
  }

  loadUrlParams(event) {
    if (event.linkEvent) {
      event.preventDefault();
    }
    const link = event.target;
    const bookingInstanceId = link.dataset.bookingInstanceId;
    const reasonInputTarget =
      this.#findReasonInputTargetForBookingInstance(bookingInstanceId);
    const initialReasonText = reasonInputTarget?.value;
    const url = new URL(link.href);
    // If editing retain the reason, if changing status clear reason
    if (
      (this.isInstanceRejected(bookingInstanceId) &&
        link.dataset.bookingsApprovalsFormTarget === 'rejectButton') ||
      (this.isInstanceApproved(bookingInstanceId) &&
        link.dataset.bookingsApprovalsFormTarget === 'approveButton')
    ) {
      url.searchParams.set('reason', initialReasonText);
    } else {
      url.searchParams.set('reason', '');
    }
    link.href = url.toString();
  }

  /**
   * @param {string} facilityId ID of the booking instance's facility.
   * @param {string} bookingInstanceId ID of the booking instance being edited.
   * @param {string} bookingLinkId ID of the instances booking link being edited.
   * @param {string} originalHireCharge The currently persisted booking instance net hire charge value.
   * @param {string} latestHireCharge The latest, modified or not, booking instance net hire charge value.
   */
  #showEditModal({
    facilityId,
    bookingInstanceId,
    bookingLinkId,
    originalHireCharge,
    latestHireCharge,
  }) {
    this.dispatch('showEditModal', {
      prefix: 'bookings-approvals-form',
      detail: {
        facilityId,
        bookingInstanceId,
        bookingLinkId,
        originalHireCharge,
        latestHireCharge,
      },
    });
  }

  /**
   * @param {string} bookingInstanceId
   * @param {string} facilityId
   * @param {'approve' | 'reject'} decision
   * @param {string} reason
   */
  #decideForBookingInstance({
    bookingInstanceId,
    facilityId,
    decision,
    reason,
  }) {
    const statusTarget = this.statusTargets.find(
      (target) => target.dataset.bookingInstanceId === bookingInstanceId
    );
    const reasonInputTarget = this.reasonInputTargets.find(
      (target) => target.dataset.bookingInstanceId === bookingInstanceId
    );

    statusTarget.value = decision;
    reasonInputTarget.value = reason;
    this.#activateButton(bookingInstanceId, decision);
    this.#recalculateTotalCustomerPriceForFacility(facilityId);
  }

  /**
   * @param {string} facilityId
   * @param {'approve_all' | 'reject_all'} decision
   * @param {string} reason
   */
  #decideForAllFacilityBookingInstances({ facilityId, decision, reason }) {
    // Set the reason for all bookings of facility
    this.reasonInputTargets.forEach((target) => {
      if (target.dataset.facilityId === facilityId) {
        target.value = reason;
      }
    });

    // Mark all booking instances decision radio for facility
    const instanceDecision = decision?.split('_all')[0];
    this.statusTargets.forEach((target) => {
      if (target.dataset.facilityId === facilityId) {
        target.value = instanceDecision;
      }
    });
    this.#activateButtonsForFacility(facilityId, decision);

    this.#recalculateTotalCustomerPriceForFacility(facilityId);
  }

  /**
   * @param {string} bookingInstanceId
   */
  #findReasonInputTargetForBookingInstance(bookingInstanceId) {
    return this.reasonInputTargets.find((target) => {
      return target.dataset.bookingInstanceId === bookingInstanceId;
    });
  }

  /**
   * @param {string} bookingInstanceId
   */
  #findCustomerPriceColumnTextTargetForBookingInstance(bookingInstanceId) {
    return this.customerPriceColumnTextTargets.find((target) => {
      return target.dataset.bookingInstanceId === bookingInstanceId;
    });
  }

  /**
   * @param {string} bookingLinkId
   */
  #findCustomerPriceColumnTextTargetForBookingLink(bookingLinkId) {
    return this.customerPriceColumnTextTargets.filter((target) => {
      return target.dataset.bookingLinkId === bookingLinkId;
    });
  }

  /**
   * @param {string} bookingInstanceId
   */
  #findHireChargeInputTargetForBookingInstance(bookingInstanceId) {
    return this.hireChargeInputTargets.find((target) => {
      return target.dataset.bookingInstanceId === bookingInstanceId;
    });
  }

  /**
   * @param {string} bookingLinkId
   */
  #findHireChargeInputTargetsForBookingLink(bookingLinkId) {
    return this.hireChargeInputTargets.filter((target) => {
      return target.dataset.bookingLinkId === bookingLinkId;
    });
  }

  /**
   * Set the total booking price by summing the total customer price of all facilities
   * for this booking and then Updates the text displayed.
   */
  #recalculateTotalCustomerPriceForBooking() {
    const total = _.sum(
      this.totalCustomerPriceForFacilityTextTargets.map((target) => {
        // Parse as number
        return parseFormattedMoney(target.innerText);
      })
    );

    this.totalCustomerPriceForBookingTextTarget.innerText =
      formatAsMoneyString(total);
  }

  /**
   * Sum customer price for all booking instances of a facility and update the
   * text displayed in the DOM.
   *
   * @param {string} facilityId
   */
  #recalculateTotalCustomerPriceForFacility(facilityId) {
    const total = _.sum(
      this.customerPriceColumnTextTargets
        .filter((target) => {
          // Filter by facility
          return (
            target.dataset.facilityId === facilityId &&
            !this.isInstanceRejected(target.dataset.bookingInstanceId)
          );
        })
        .map((target) => {
          // Parse as number
          return parseFormattedMoney(target.innerText);
        })
    );

    const totalCustomerPriceForFacilityTextTarget =
      this.totalCustomerPriceForFacilityTextTargets.find((target) => {
        return target.dataset.facilityId === facilityId;
      });
    totalCustomerPriceForFacilityTextTarget.innerText =
      formatAsMoneyString(total);

    // Recalculate total booking price
    this.#recalculateTotalCustomerPriceForBooking();
    this.#handleAllBookingInstancesDecided();
  }

  /**
   * return whether booking instance has a status of rejected
   *
   * @param {string} bookingInstanceId
   * @return {boolean}
   */
  isInstanceRejected(bookingInstanceId) {
    return this.statusTargets.find((target) => {
      return (
        target.dataset.bookingInstanceId === bookingInstanceId &&
        target.value === 'reject'
      );
    });
  }

  isInstanceApproved(bookingInstanceId) {
    return this.statusTargets.find((target) => {
      return (
        target.dataset.bookingInstanceId === bookingInstanceId &&
        target.value === 'approve'
      );
    });
  }

  /**
   * All radio groups should have at least one radio that is checked, that is, decision for the booking instance
   * has been made.
   * @return {boolean}
   */
  #hasAllBookingInstancesDecided() {
    return this.statusTargets
      .map((target) => target.value)
      .every((value) => value !== '');
  }

  #hasAllBookingInstancesRejected() {
    return this.statusTargets
      .map((target) => target.value)
      .every((value) => value === 'reject');
  }

  /**
   * Enable the submit button when all booking instances have been decided, otherwise, disable it.
   */
  #handleAllBookingInstancesDecided() {
    const allBookingInstancesDecided = this.#hasAllBookingInstancesDecided();

    this.setElementEnabled(this.submitButtonTarget, allBookingInstancesDecided);

    // Update the submit button label according to the decisions takens
    if (this.#hasAllBookingInstancesRejected()) {
      this.submitButtonTarget.value =
        this.submitButtonTarget.dataset.allRejectedLabel;
    } else {
      this.submitButtonTarget.value =
        this.submitButtonTarget.dataset.allApprovedLabel;
    }
  }

  #activateButton(bookingInstanceId, decision) {
    if (decision === 'approve') {
      this.#activateApproveButton(bookingInstanceId);
    } else {
      this.#activateRejectButton(bookingInstanceId);
    }
  }

  #activateButtonsForFacility(facilityId, decision) {
    if (decision === 'approve_all') {
      this.#activateApproveButtonsForFacility(facilityId);
    } else {
      this.#activateRejectButtonsForFacility(facilityId);
    }
  }

  #activateApproveButton(bookingInstanceId) {
    const approveButton = this.approveButtonTargets.find(
      (target) => target.dataset.bookingInstanceId === bookingInstanceId
    );
    approveButton.classList.add('btn-success');
    approveButton.classList.remove('btn-outline-success');
    const rejectButton = this.rejectButtonTargets.find(
      (target) => target.dataset.bookingInstanceId === bookingInstanceId
    );
    rejectButton.classList.add('btn-outline-danger');
    rejectButton.classList.remove('btn-danger');
  }

  #activateApproveButtonsForFacility(facilityId) {
    this.approveButtonTargets.forEach((target) => {
      if (target.dataset.facilityId === facilityId) {
        target.classList.add('btn-success');
        target.classList.remove('btn-outline-success');
      }
    });
    this.rejectButtonTargets.forEach((target) => {
      if (target.dataset.facilityId === facilityId) {
        target.classList.add('btn-outline-danger');
        target.classList.remove('btn-danger');
      }
    });
  }

  #activateRejectButton(bookingInstanceId) {
    const approveButton = this.approveButtonTargets.find(
      (target) => target.dataset.bookingInstanceId === bookingInstanceId
    );
    approveButton.classList.add('btn-outline-success');
    approveButton.classList.remove('btn-success');
    const rejectButton = this.rejectButtonTargets.find(
      (target) => target.dataset.bookingInstanceId === bookingInstanceId
    );
    rejectButton.classList.add('btn-danger');
    rejectButton.classList.remove('btn-outline-danger');
  }

  #activateRejectButtonsForFacility(facilityId) {
    this.approveButtonTargets.forEach((target) => {
      if (target.dataset.facilityId === facilityId) {
        target.classList.add('btn-outline-success');
        target.classList.remove('btn-success');
      }
    });
    this.rejectButtonTargets.forEach((target) => {
      if (target.dataset.facilityId === facilityId) {
        target.classList.add('btn-danger');
        target.classList.remove('btn-outline-danger');
      }
    });
  }

  #initButtonStyling() {
    this.statusTargets.forEach((status) => {
      const bookingInstanceId = status.dataset.bookingInstanceId;
      if (status.value == 'approve') {
        this.#activateApproveButton(bookingInstanceId);
      }
      if (status.value == 'reject') {
        this.#activateRejectButton(bookingInstanceId);
      }
    });
  }
}
