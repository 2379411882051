import { Controller } from '@hotwired/stimulus';
import ApexCharts from 'apexcharts';
import { jsPDF } from 'jspdf';

// Connects to data-controller="dashboard-graph"
export default class extends Controller {
  static values = {
    chartId: String,
    filename: String,
  };

  download = async () => {
    const apxChart = ApexCharts.getChartByID(this.chartIdValue);
    const { imgURI } = await apxChart.dataURI();
    const pdf = new jsPDF('l', 'mm', [297, 210]);
    pdf.addImage(imgURI, 'PNG', 20, 20);
    pdf.save(`${this.filenameValue}.pdf`);
  };
}
