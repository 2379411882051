import $ from 'jquery';

export const useSort = (controller) => {
  Object.assign(controller, {
    initSort() {
      $(controller.sortOptionTargets).each((index, optionTarget) => {
        const $option = $(optionTarget);
        const option = $option.data('sort-option');
        const icon = this.sortIcon(option);
        $option.append(icon);
      });
    },

    sort(e) {
      const $option = $(e.target).closest('[data-sort-option]');
      const option = $option.data('sort-option');
      let direction = 'asc';

      if (this.sortOptionInputTarget.value === option) {
        direction =
          this.sortDirectionInputTarget.value === 'asc' ? 'desc' : 'asc';
      }

      this.sortOptionInputTarget.value = option;
      this.sortDirectionInputTarget.value = direction;

      controller.submit();
    },

    sortIcon(option) {
      const iconClass = this.sortIconClass(option);
      return $('<span />', { class: `bi ${iconClass} ms-2` });
    },

    sortIconClass(option) {
      if (this.sortOptionInputTarget.value !== option) {
        return 'bi-arrow-down-up';
      }

      if (this.sortDirectionInputTarget.value === 'desc') {
        return 'bi-arrow-up';
      }

      return 'bi-arrow-down';
    },
  });
};
