import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="frame-reloader"
export default class extends Controller {
  static values = {
    spinner: String,
  };

  connect() {
    if (this.spinnerValue) {
      $(this.element).on('turbo:before-fetch-request', (event) => {
        event.target.innerHTML = this.spinnerValue;
      });
    }
  }

  reload() {
    this.element.reload();
  }
}
