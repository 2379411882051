import $ from 'jquery';
import { Controller } from '@hotwired/stimulus';
import { useElementEnabled } from './mixins/useElementEnabled';
import { useElementVisibility } from './mixins/useElementVisibility';

// Connects to data-controller="stripe-payment-element"
export default class extends Controller {
  static targets = ['element', 'message'];
  static values = {
    pubApiKey: String,
    createIntentPath: String,
    paymentCompleteUrl: String,
  };

  connect() {
    useElementEnabled(this);
    useElementVisibility(this);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.formTarget = this.getFormTarget();
    this.formTarget.addEventListener('submit', this.handleSubmit);

    // Prevent Rails "data-disable-with" being handled when this controller is active
    // so it doesn't interfere with the `#setLoading`.
    // - This is then reinstated on controller `disconnect`.
    this.#preventRailsDisableWithAttributeOnSubmitTarget();

    this.stripe = window.Stripe(this.pubApiKeyValue);
    this.elements = null;
    this.paymentElement = null;

    this.initializeIntent();
  }

  disconnect() {
    if (this.paymentElement) {
      this.paymentElement.unmount();
    }

    this.#reinstateRailsDisableWithAttributeOnSubmitTarget();
    this.formTarget.removeEventListener('submit', this.handleSubmit);
  }

  handleSubmit(e) {
    e.preventDefault();

    if (!this.formTarget.reportValidity()) {
      return false;
    }

    this.setLoading(true);
    const elements = this.elements;

    this.stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: this.paymentCompleteUrlValue,
        },
      })
      .then((result) => {
        if (result.error) {
          if (
            result.error.type === 'card_error' ||
            result.error.type === 'validation_error'
          ) {
            this.showMessage(result.error.message);
          } else {
            this.showMessage('An unexpected error occurred.');
          }
        }
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  initializeIntent() {
    $.post(this.createIntentPathValue, (response) => {
      const { clientSecret, ...options } = response.data.attributes;
      this.initializeElement(clientSecret, options);
    });
  }

  initializeElement(clientSecret, options = {}) {
    this.elements = this.loadElements(clientSecret);

    this.paymentElement = this.elements.create('payment', options);
    this.paymentElement.mount(this.elementTarget);
  }

  loadElements(clientSecret) {
    const appearance = {
      theme: 'stripe',
      variables: {
        colorPrimary: '#664fc3',
      },
    };

    return this.stripe.elements({ appearance, clientSecret });
  }

  showMessage(messageText) {
    this.messageTarget.textContent = messageText;
    this.setElementVisible(this.messageTarget, true);

    if (this.messageTargetTimeoutRef) {
      // Clear any previous timeout for the message target, so it doesn't flash when applied consecutively
      clearTimeout(this.messageTargetTimeoutRef);
    }

    this.messageTargetTimeoutRef = setTimeout(() => {
      this.setElementVisible(this.messageTarget, false);
      this.messageTarget.textContent = '';
    }, 4000);
  }

  setLoading(isLoading) {
    if (isLoading) {
      this.setElementEnabled(this.submitTarget, false);
      this.setElementVisible(this.spinnerTarget, true);
      this.setElementVisible(this.submitTextTarget, false);
    } else {
      this.setElementEnabled(this.submitTarget, true);
      this.setElementVisible(this.spinnerTarget, false);
      this.setElementVisible(this.submitTextTarget, true);
    }
  }

  getFormTarget() {
    return $(this.element).closest('form[data-stripe-payment-form]').get(0);
  }

  #preventRailsDisableWithAttributeOnSubmitTarget() {
    this.previousSubmitTargetDisableWithValue =
      this.submitTarget.dataset.disableWith;
    if (this.previousSubmitTargetDisableWithValue) {
      delete this.submitTarget.dataset.disableWith;
    }
  }

  #reinstateRailsDisableWithAttributeOnSubmitTarget() {
    if (this.previousSubmitTargetDisableWithValue) {
      this.submitTarget.dataset.disableWith =
        this.previousSubmitTargetDisableWithValue;
    }
  }

  get submitTarget() {
    return this.formTarget.querySelector('#submit');
  }

  get spinnerTarget() {
    return this.formTarget.querySelector('#submit-spinner');
  }

  get submitTextTarget() {
    return this.formTarget.querySelector('#submit-text');
  }
}
