import { Controller } from '@hotwired/stimulus';
import imageCompression from 'browser-image-compression';

// Connects to data-controller="form-photos"
export default class FormPhotosController extends Controller {
  static MAX_WIDTH = 800;
  static MAX_SIZE_MB = 0.2;

  static targets = [
    'preview',
    'fileInput',
    'fileInputContainer',
    'deleteButton',
    'deleteButtonTemplate',
    'newPhotoIds',
    'existingPhotoIds',
    'buttonText',
    'spinner',
  ];

  inputChange(event) {
    const fileInput = event.target;
    const files = fileInput.files;
    this.renderFileInputs();
    this.renderPreviewsAndCompress(files, fileInput);
  }

  renderPreviewsAndCompress(files, fileInput) {
    this.fileInputTarget.disabled = true;
    this.buttonTextTarget.classList.add('d-none');
    this.spinnerTarget.classList.remove('d-none');
    let list = new DataTransfer();
    for (const file of files) {
      const newPhotoIds = JSON.parse(this.newPhotoIdsTarget.value);
      const reader = new FileReader();
      reader.onload = (e) => {
        const col = this.createCol();
        this.handleImageUpload(file).then((compressedFile) => {
          this.addToNewPhotoIds(compressedFile);
          list.items.add(compressedFile);
          fileInput.files = list.files;
          if (newPhotoIds.includes(compressedFile.name)) {
            this.showDuplicatePhotoAlert(file.name);
            return;
          }
          this.addImageToCol(e.target.result, col);
          this.addDeleteToCol(compressedFile, col);
          this.previewTarget.appendChild(col);
          this.fileInputTarget.disabled = false;
          this.spinnerTarget.classList.add('d-none');
          this.buttonTextTarget.classList.remove('d-none');
        });
      };
      reader.readAsDataURL(file);
    }
  }

  showDuplicatePhotoAlert(photoName) {
    alert(
      `Photo ${photoName} has already been added and will not be included.`
    );
  }

  addToNewPhotoIds(file) {
    const newPhotoIds = JSON.parse(this.newPhotoIdsTarget.value);
    newPhotoIds.push(file.name);
    this.newPhotoIdsTarget.value = JSON.stringify(newPhotoIds);
  }

  removeFromPhotoIds(photoId, photoIdsTarget) {
    const photoIds = JSON.parse(photoIdsTarget.value);
    const filteredPhotoIds = photoIds.filter((e) => e !== photoId);
    photoIdsTarget.value = JSON.stringify(filteredPhotoIds);
  }

  removeFromNewPhotoIds(photoId) {
    this.removeFromPhotoIds(photoId, this.newPhotoIdsTarget);
  }

  removeFromExistingPhotoIds(photoId) {
    this.removeFromPhotoIds(photoId, this.existingPhotoIdsTarget);
  }

  addDeleteToCol(file, col) {
    const deleteButton = this.deleteButtonTemplateTarget.cloneNode(true);
    deleteButton.removeAttribute('data-form-photos-target');
    deleteButton.setAttribute('data-photo-id', file.name);
    deleteButton.classList.remove('d-none');
    col.appendChild(deleteButton);
  }

  addImageToCol(src, col) {
    const imageContainer = document.createElement('div');
    imageContainer.classList.add('image-container');
    const img = document.createElement('img');
    img.classList.add('img-fit-cover');
    img.src = src;
    imageContainer.appendChild(img);
    col.appendChild(imageContainer);
  }

  createCol() {
    const col = document.createElement('div');
    col.classList.add('col-6', 'col-md-4', 'col-lg-3', 'g-3', 'image-col');
    return col;
  }

  deleteConfirmation() {
    return confirm('Are you sure you want to delete this photo?');
  }

  deletePhoto(e, photoIdsTarget) {
    if (this.deleteConfirmation()) {
      const deleteButton = e.target.closest('[data-photo-id]');
      const { photoId } = deleteButton.dataset;
      this.removeFromPhotoIds(photoId, photoIdsTarget);
      const imageCol = e.target.closest('.image-col');
      imageCol.remove();
    }
  }

  deleteNewPhoto(e) {
    this.deletePhoto(e, this.newPhotoIdsTarget);
  }

  deleteExistingPhoto(e) {
    this.deletePhoto(e, this.existingPhotoIdsTarget);
  }

  renderFileInputs() {
    const clonedInput = this.fileInputTarget.cloneNode(true);
    clonedInput.value = '';
    this.fileInputContainerTarget.insertBefore(
      clonedInput,
      this.fileInputContainerTarget.firstChild
    );
  }

  uniqueFileName(file, filesize) {
    return `${this.filenameWithoutType(file)}:${filesize}.jpeg`;
  }

  filenameWithoutType(file) {
    const filename = file.name;
    const lastDotIndex = filename.lastIndexOf('.');
    return filename.slice(0, lastDotIndex);
  }

  async handleImageUpload(originalFile) {
    const options = {
      maxSizeMB: FormPhotosController.MAX_SIZE_MB,
      maxWidthOrHeight: FormPhotosController.MAX_WIDTH,
      useWebWorker: true,
    };
    try {
      const blob = await imageCompression(originalFile, options);
      const fileType = 'image/jpeg';
      return new File([blob], this.uniqueFileName(originalFile, blob.size), {
        type: fileType,
      });
    } catch (error) {
      console.log(error);
    }
  }
}
