/**
 * A stimulus controller mixin to show / hide target elements.
 * Exposes `#setElementVisible(element, isVisible: boolean = true)`
 *
 * @param controller - Stimulus controller to attach this mixin.
 */
export const useElementVisibility = (controller) => {
  Object.assign(controller, {
    /**
     * Whether or not to set the {@link element} to be visible.
     *
     * @param {Element?} element - The element to change its visibility
     * @param {boolean} isVisible - True (default) to make the element visible, otherwise hide it.
     */
    setElementVisible(element, isVisible = true) {
      if (!element) {
        // Do nothing when no element
        return;
      }

      if (isVisible) {
        element.classList.add('d-block');
        element.classList.remove('d-none');
      } else {
        element.classList.add('d-none');
        element.classList.remove('d-block');
      }
    },
  });
};
