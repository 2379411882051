import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="routine--handover-task"
export default class extends Controller {
  static targets = [
    'customerComment',
    'signature',
    'safetyBriefingCompleted',
    'status',
  ];

  connect() {
    this.statusTarget.dispatchEvent(new Event('change'));
  }

  statusChange(e) {
    switch (e.target.value) {
      case ('proceeded', 'declined'):
        this.resetForm();
        break;
      case 'no_show':
        this.setupNoShow();
        break;
      default:
        this.resetForm();
        break;
    }
  }

  resetForm() {
    this.setElementDisplay(this.signatureTarget, 'block');
    this.setElementDisplay(this.customerCommentTarget, 'block');
    this.setElementDisplay(this.safetyBriefingCompletedTarget, 'block');
  }

  setupNoShow() {
    this.setElementDisplay(this.signatureTarget, 'none');
    this.setElementDisplay(this.customerCommentTarget, 'none');
    this.setElementDisplay(this.safetyBriefingCompletedTarget, 'none');
  }

  setElementDisplay(element, display) {
    element.style.display = display;
  }
}
