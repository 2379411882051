// From https://github.com/hotwired/turbo/issues/138#issuecomment-802171574
import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static values = {
    fullPageReload: false,
    anchor: String, // anchors do not get passed in with the event so access from a data value
  };

  connect() {
    this.element.addEventListener('turbo:submit-end', (event) => {
      this.next(event);
    });
  }

  next(event) {
    const url = `${event.detail.fetchResponse.response.url}#${this.anchorValue}`;
    if (event.detail.success) {
      if (this.fullPageReloadValue) {
        window.location.href = url;
      } else {
        Turbo.visit(url);
      }
    }
  }
}
