import CalendarController from './calendar_controller';
import Calendar from 'tui-calendar-original';

// Connects to data-controller="calendar-original"
export default class extends CalendarController {
  initCalendar() {
    this.calendar = new Calendar(
      this.calendarTarget,
      this.calendarOptionsValue
    );
    this.setCalendarEventHandlers();
  }
}
