import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'container', 'customPriceInUse'];

  static values = {
    rate: Number,
    customPriceExists: Boolean,
  };

  initialize() {
    if (this.customPriceExistsValue) {
      this.disableInput();
    }
  }

  reset() {
    this.inputTarget.value = this.rateValue.toFixed(2);
  }

  disableInput() {
    this.inputTarget.disabled = true;
    this.containerTarget.classList.add('d-none');
    this.customPriceInUseTarget.classList.remove('d-none');
  }

  enableInput() {
    this.inputTarget.disabled = false;
    this.containerTarget.classList.remove('d-none');
    this.customPriceInUseTarget.classList.add('d-none');
  }
}
