import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['map'];
  static values = {
    lat: Number,
    lng: Number,
    key: String,
    zoom: Number,
    size: String,
  };

  initialize() {
    this.mapTarget.src = this.staticMap();
  }

  staticMapUrl() {
    return 'https://maps.googleapis.com/maps/api/staticmap?';
  }

  staticMap() {
    const url = this.staticMapUrl().concat(
      `key=${this.keyValue}`,
      `&zoom=${this.zoomValue}`,
      `&size=${this.sizeValue}`,
      `&markers=${this.latValue},${this.lngValue}`,
      '&scale=2'
    );

    return encodeURI(url);
  }
}
