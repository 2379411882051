import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="facility-wizard"
export default class extends Controller {
  static targets = [
    'facilityTypeInput',
    'facilityTypeInputLabel',
    'facilityTypeSportsFacilityInput',
  ];

  updateInput(event) {
    const { value, sports_facility } = event.detail;
    this.facilityTypeInputTarget.value = value;
    this.facilityTypeInputLabelTarget.value = value;
    this.facilityTypeSportsFacilityInputTarget.value = sports_facility || false;
  }
}
