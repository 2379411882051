import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';
import Rails from '@rails/ujs';

// Connects to data-controller="routine--tasks-index"
export default class extends Controller {
  static targets = ['filterDropdown', 'filterForm', 'filterViewInput'];
  static values = {
    spinner: String,
  };

  connect() {
    window.addEventListener(
      'routine:context:changed',
      this.contextChanged.bind(this)
    );
  }

  contextChanged() {
    const url = this.entriesTargetURL;

    let viewParam = this.filterViewInputTarget.value
      ? this.filterViewInputTarget.value
      : url.searchParams.get('view');

    const params = new URLSearchParams({
      view: viewParam,
    });
    url.search = params.toString();
    this.entriesTarget.src = url.toString();
    this.entriesTarget.reload();
  }

  clearFilter() {
    this.resetFilter();
    Rails.fire(this.filterFormTarget, 'submit');
    this.hideFilter();
  }

  resetFilter() {
    this.filterFormTarget.reset();
    $(this.filterFormTarget)
      .find('[data-select2-input]')
      .val('')
      .trigger('change');
  }

  toggleFilter() {
    this.filterDropdown.toggle();
  }

  hideFilter() {
    this.filterDropdown.hide();
  }

  onLoad() {
    this.entriesTarget.innerHTML = this.spinnerValue;
  }

  toggleView(e) {
    const url = this.entriesTargetURL;
    const params = url.searchParams;
    const newView = params.get('view') === 'list' ? 'group' : 'list';

    params.set('view', newView);
    url.search = params.toString();
    this.entriesTarget.src = url.toString();

    this.filterViewInputTarget.value = newView;

    const icon = $('<span />', {
      class: `bi bi-${newView === 'list' ? 'view-stacked' : 'list'} fs-0`,
    });
    e.currentTarget.innerHTML = icon.prop('outerHTML');
  }

  get filterDropdown() {
    return bootstrap.Dropdown.getInstance(this.filterDropdownTarget);
  }

  get entriesTarget() {
    return document.getElementById('routine_task_entries');
  }

  get entriesTargetURL() {
    return new URL(this.entriesTarget.src, window.location.origin);
  }
}
