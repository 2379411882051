import SearchController from './search_controller.js';
import { useSort } from './mixins/useSort';

// Connects to data-controller="bookings-search"
export default class extends SearchController {
  static targets = ['sortOption', 'sortOptionInput', 'sortDirectionInput'];

  initialize() {
    super.initialize();

    useSort(this);
    this.initSort();
  }

  onSuccess(e) {
    super.onSuccess(e);
    this.initSort();
  }

  reload() {
    this.submit();
  }
}
