import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'label', 'checkBox'];
  static values = {
    label: String,
    suffix: String,
  };

  initialize() {
    this.updateLabel();
    this.checkBoxTarget.addEventListener('change', this.rangeToggle.bind(this));
  }

  updateLabel() {
    this.labelTarget.innerHTML = `${this.labelValue} ${this.inputTarget.value} ${this.suffixValue}`;
  }

  rangeToggle() {
    this.inputTarget.disabled = this.checkBoxTarget.checked;

    if (this.checkBoxTarget.checked) {
      this.labelTarget.innerHTML = 'Searching all locations...';
    } else {
      this.updateLabel();
    }
  }
}
