import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="inherit-opening-hours-form"
export default class extends Controller {
  static targets = ['form'];

  submitForm(e) {
    if (!e.target.checked) this.formTarget.requestSubmit();
    else if (
      confirm('Facility will return to Venue opening hours, are you sure?')
    )
      this.formTarget.requestSubmit();
    else e.target.checked = !e.target.checked;
  }
}
