import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="move-booking-select"
export default class extends Controller {
  static values = {
    newBookingTimePath: String,
  };

  static targets = ['newBookingTime', 'startTimeInput', 'endTimeInput'];

  startTimeChanged(e) {
    const newStartTime = e.target.value;

    const originalStartDate = new Date(
      this.startTimeInputTarget.value.replace(' ', 'T').replace(' +', '+')
    );
    const originalEndDate = new Date(
      this.endTimeInputTarget.value.replace(' ', 'T').replace(' +', '+')
    );
    const diff = originalEndDate - originalStartDate;
    const newStartDate = new Date(originalStartDate);
    newStartDate.setHours(
      newStartTime.split(':')[0],
      newStartTime.split(':')[1],
      0,
      0
    );
    const newEndDate = new Date(newStartDate.valueOf() + diff);

    this.setNewBookingTime(newStartDate, newEndDate);
  }

  setNewBookingTime(startDate = null, endDate = null) {
    const src =
      startDate && endDate
        ? this.newBookingTimePathValue +
          '?' +
          'start_time=' +
          startDate.toISOString() +
          '&end_time=' +
          endDate.toISOString()
        : this.newBookingTimePathValue;
    this.newBookingTimeTarget.src = src;
    if (startDate) {
      this.startTimeInputTarget.value = startDate.toISOString();
    }
    if (endDate) {
      this.endTimeInputTarget.value = endDate.toISOString();
    }
  }
}
