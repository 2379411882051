import { Controller } from '@hotwired/stimulus';
import * as Request from '@rails/request.js';
import { useElementVisibility } from './mixins/useElementVisibility';

// Connects to data-controller="notifications-count-badge"
export default class extends Controller {
  static targets = ['count', 'container'];

  static values = {
    url: String,
  };

  connect() {
    useElementVisibility(this);
    this.#updateCountElement();
  }

  #updateCountElement() {
    this.#fetchCount()
      .then((count) => {
        this.countTargets.forEach((target) => {
          this.setElementVisible(target, count);
          target.innerText = count > 99 ? '99+' : count;
        });

        // Show a descriptive title on mouse hover
        this.containerTarget.title = `You have ${count} unread ${
          count === 1 ? 'notification' : 'notifications'
        } `;
      })
      .catch((error) => {
        console.error('Failed to fetch the number of notifications', error);
      });
  }

  /**
   * Fetches the number of notifications from {@link this.urlValue}.
   *
   * @return {Promise<number>} The number of notifications.
   */
  async #fetchCount() {
    if (!this.urlValue) {
      return 0;
    }

    const request = await Request.get(this.urlValue, {
      responseKind: 'json',
    });

    /**
     * @type {{ notifications_count: number }} response
     */
    const response = await request.response.json();
    return response.notifications_count || 0;
  }
}
