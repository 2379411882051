import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="payment-threshold"
export default class extends Controller {
  connect() {
    // controller is only connected when alert is to be rendered, on dispatch event to disable submit / show alert
    const disableSubmitEvent = new CustomEvent('payment:form:remove');
    window.dispatchEvent(disableSubmitEvent);
  }
}
