import { Controller } from '@hotwired/stimulus';
import moment from 'moment';
import Calendar from 'tui-calendar';
import 'tui-calendar/dist/tui-calendar.min.css';
import 'tui-date-picker/dist/tui-date-picker.min.css';
import 'tui-time-picker/dist/tui-time-picker.min.css';
import _ from 'lodash';

// Connects to data-controller="calendar"
export default class extends Controller {
  static targets = [
    'calendar',
    'calendarContainer',
    'daySelector',
    'renderRangeLabel',
    'dayMenu',
    'calendarViewButton',
  ];

  static calendarTheme = {
    'week.timegridOneHour.height': '26px',
    'week.timegridHalfHour.height': '13px',
  };

  static values = {
    calendarOptions: {
      defaultView: 'week',
      taskView: false,
      calendars: [],
      theme: this.calendarTheme,
      scheduleView: ['time'],
      month: {
        startDayOfWeek: 1,
      },
      week: {
        startDayOfWeek: 1,
      },
    },
    latestConfig: Object,
    schedulesUrl: String,
  };

  initCalendar() {
    this.calendar = new Calendar(
      this.calendarTarget,
      this.calendarOptionsValue
    );
    this.setCalendarEventHandlers();
  }

  timeOnly(date) {
    return new Date(date).toLocaleTimeString('en-GB', {
      timeZone: 'Europe/London',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  stripTimeZone(date) {
    date = date.replace('Z', '');
    return date;
  }

  getSchedulesRequest(schedulesUrl = this.schedulesUrlValue, optionData = {}) {
    this.schedulesUrlValue = schedulesUrl;
    this.calendarContainerTarget.classList.add('loading');
    const startDate = this.calendar.getDateRangeStart().getTime();
    const endDate = this.calendar.getDateRangeEnd().getTime();

    const data = {
      start_time: startDate,
      end_time: endDate,
    };

    const params = new URLSearchParams({ ...data, ...optionData });

    fetch(this.schedulesUrlValue + '?' + params, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => this.getSchedulesRequestsResponse(res))
      .catch((error) => {
        console.warn('error loading calender events', error);
      })
      .finally(() => {
        this.calendarContainerTarget.classList.remove('loading');
        this.setRenderRangeText();
      });
  }

  removeCalendarClasses() {
    const calendarClasses = [
      'day-view',
      'same-day-view',
      'week-view',
      'month-view',
    ];
    calendarClasses.forEach((c) => this.calendarTarget.classList.remove(c));
  }

  updateLatestConfigValue(newConfig) {
    this.latestConfigValue = {
      ...this.latestConfigValue,
      ...newConfig,
    };
  }

  setDayMenuStatus(day) {
    this.daySelectorTargets.forEach((d) => {
      const { searchBookCalendarDayParam } = d.dataset;
      if (searchBookCalendarDayParam == day) {
        d.classList.add('btn-primary');
        d.classList.remove('btn-outline-primary');
      } else {
        d.classList.add('btn-outline-primary');
        d.classList.remove('btn-primary');
      }
    });
  }

  calendarNext() {
    this.calendar.next();
    this.getSchedulesRequest();
  }

  calendarPrev() {
    this.calendar.prev();
    this.getSchedulesRequest();
  }

  calendarToday() {
    this.calendar.today();
    this.getSchedulesRequest();
  }

  calendarSetDay({ params: { day } }) {
    this.setSameDayView(day);
  }

  setDayView() {
    this.hideDayMenu();
    this.removeCalendarClasses();
    this.calendarTarget.classList.add('day-view');
    this.calendar.changeView('day', true);
    this.updateLatestConfigValue({
      view: 'day',
    });
    this.getSchedulesRequest();
    this.setCalendarViewButtonStates('day-view');
  }

  setSameDayView(day) {
    if (day instanceof PointerEvent) day = 1; // view menu event
    this.showDayMenu();
    this.removeCalendarClasses();
    this.calendarTarget.classList.add('same-day-view');
    if (this.hasDaySelectorTarget) this.setDayMenuStatus(day);
    let options = this.calendar.getOptions();
    options.week.startDayOfWeek = day;
    options.week.sameDayEachWeek = true;
    this.calendar.setOptions(options, true);
    this.calendar.changeView('week', true);
    this.updateLatestConfigValue({
      view: 'same-day',
      day: day,
    });
    this.getSchedulesRequest();
    this.setCalendarViewButtonStates('same-day-view');
  }

  setWeekView() {
    this.hideDayMenu();
    this.removeCalendarClasses();
    this.calendarTarget.classList.add('week-view');
    let options = this.calendar.getOptions();
    options.week.startDayOfWeek = 1;
    options.week.sameDayEachWeek = false;
    this.calendar.setOptions(options, true);
    this.calendar.changeView('week', true);
    this.updateLatestConfigValue({
      view: 'week',
    });
    this.getSchedulesRequest();
    this.setCalendarViewButtonStates('week-view');
  }

  setMonthView() {
    this.hideDayMenu();
    this.removeCalendarClasses();
    this.calendarTarget.classList.add('month-view');
    this.calendar.changeView('month', true);
    this.updateLatestConfigValue({
      view: 'month',
    });
    this.getSchedulesRequest();
    this.setCalendarViewButtonStates('month-view');
  }

  setCalendarViewButtonStates = (activeId) => {
    this.calendarViewButtonTargets.forEach((button) => {
      if (button.dataset.id === activeId) {
        button.classList.add('btn-primary');
        button.classList.remove('btn-outline-primary');
      } else {
        button.classList.add('btn-outline-primary');
        button.classList.remove('btn-primary');
      }
    });
  };

  setRenderRangeText() {
    const options = this.calendar.getOptions();
    const viewName = this.calendar.getViewName();
    const html = [];
    const dayDateFormat = 'ddd, Do MMMM';
    if (viewName === 'day') {
      html.push(
        moment(this.calendar.getDate().toLocalTime().getTime()).format(
          `${dayDateFormat} YYYY`
        )
      );
    } else if (
      viewName === 'month' &&
      (!options.month.visibleWeeksCount || options.month.visibleWeeksCount > 4)
    ) {
      html.push(
        moment(this.calendar.getDate().toLocalTime().getTime()).format(
          'MMMM YYYY'
        )
      );
    } else if (options.week.sameDayEachWeek) {
      html.push(
        moment(this.calendar.getDate().toLocalTime().getTime()).format(
          'MMMM YYYY'
        )
      );
    } else {
      html.push(
        moment(
          this.calendar.getDateRangeStart().toLocalTime().getTime()
        ).format(dayDateFormat)
      );
      html.push(' to ');
      html.push(
        moment(this.calendar.getDateRangeEnd().toLocalTime().getTime()).format(
          `${dayDateFormat} YYYY`
        )
      );
    }
    if (this.hasRenderRangeLabelTarget) {
      this.renderRangeLabelTarget.innerHTML = html.join('');
    }
  }

  setCalendarEventHandlers() {
    this.calendar.on({
      clickSchedule: (e) => this.clickScheduleEvent(e),
      rightClickSchedule: (e) => this.rightClickScheduleEvent(e),
      beforeCreateSchedule: (e) => this.beforeCreateScheduleEvent(e),
      beforeUpdateSchedule: (e) => this.beforeUpdateScheduleEvent(e),
    });
  }

  startAndEndMomentFromCalendarEvent(e) {
    const startDate = moment(e.start.toDate()).set('minute', 0);
    let endDate = moment(e.end.toDate());
    if (endDate.minute() !== 0) {
      endDate.set('minute', 0);
      endDate.add(1, 'hour');
    }
    const duration = moment.duration(endDate.diff(startDate));
    if (duration.asHours() < 2) {
      endDate = startDate.clone().add(1, 'hour');
    }
    this.updateLatestConfigValue({
      startDate: startDate.toDate(),
    });
    return { startDate, endDate };
  }

  startAndEndDateFromCalendarEvent(e) {
    const { startDate, endDate } = this.startAndEndMomentFromCalendarEvent(e);
    return { startDate: startDate.toDate(), endDate: endDate.toDate() };
  }

  startAndEndTimestampFromCalendarEvent(e) {
    const { startDate, endDate } = this.startAndEndMomentFromCalendarEvent(e);
    return { start_time: startDate.format('X'), end_time: endDate.format('X') };
  }

  reloadLastView() {
    this.calendar.setDate(moment(this.latestConfigValue.startDate).toDate());
    switch (this.latestConfigValue.view) {
      case 'day':
        this.setDayView();
        break;
      case 'same-day':
        this.setSameDayView(this.latestConfigValue.day);
        break;
      case 'week':
        this.setWeekView();
        break;
      case 'month':
        this.setMonthView();
        break;
      default:
        this.setWeekView();
    }
  }

  hideDayMenu = () => {
    if (this.hasDayMenuTarget) this.dayMenuTarget.classList.add('d-none');
  };

  showDayMenu = () => {
    if (this.hasDayMenuTarget) this.dayMenuTarget.classList.remove('d-none');
  };

  // Return the calendar schedule
  getSchedules() {
    return this.calendar._controller.schedules;
  }

  getEventsForCalendarId(calendarId) {
    return _.filter(this.getSchedules().items, { calendarId: calendarId });
  }

  // Below functions should be overridden as required

  getSchedulesRequestsResponse() {
    console.error(
      'Implement getSchedulesRequestsResponse in custom calendar implementation'
    );
  }

  // eslint-disable-next-line no-unused-vars
  clickScheduleEvent(e) {}

  // eslint-disable-next-line no-unused-vars
  beforeCreateScheduleEvent(e) {}

  // eslint-disable-next-line no-unused-vars
  beforeUpdateScheduleEvent(e) {}

  // eslint-disable-next-line no-unused-vars
  rightClickScheduleEvent(e) {}
}
