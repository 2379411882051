import $ from 'jquery';
import Tooltip from 'bootstrap/js/dist/tooltip';
import { Controller } from '@hotwired/stimulus';
import { useSelect2 } from './mixins/useSelect2';

export default class extends Controller {
  static targets = ['input'];
  static values = {
    dropdownParent: String,
  };

  initialize() {
    useSelect2(this);

    document.addEventListener('turbo:before-cache', () => {
      this.destroySelectInput();
    });
  }

  connect() {
    this.tooltips = [];
    this.initSelectInput();
  }

  initSelectInput() {
    $(this.inputTarget).select2({
      templateResult: this.optionTemplate.bind(this),
      templateSelection: this.selectionTemplate.bind(this),
      width: 'style',
      dropdownParent: this.dropdownParentValue,
    });

    $(this.inputTarget).on('select2:close', () => {
      this.tooltips.forEach((tooltip) => tooltip.dispose());
      this.tooltips = [];
    });
  }

  destroySelectInput() {
    this.destroySelect2(this.inputTarget, 'select2:close');
  }

  optionTemplate(activity) {
    if (!activity.id) {
      return activity.text;
    }

    const [$activity, tooltip] = this.templateElements(activity);

    if (tooltip) {
      this.tooltips.push(tooltip);
    }

    return $activity;
  }

  selectionTemplate(activity) {
    const [$activity] = this.templateElements(activity);
    return $activity;
  }

  templateElements(activity) {
    const $activity = $('<span />', { text: activity.text });
    let tooltip = null;

    if ($(activity.element).data('acceptance-status') === 'auto') {
      const $autoIcon = $('<span />', {
        class: 'bi bi-lightning-charge-fill text-gold ms-2',
        title: 'Available for Instant Booking',
        'data-bs-toggle': 'tooltip',
        'data-bs-placement': 'top',
      });

      tooltip = new Tooltip($autoIcon.get(0));
      $autoIcon.appendTo($activity);
    }

    return [$activity, tooltip];
  }
}
