/**
 * Formats a number as human readable GBP money.
 *
 * @param {string | number} money The money being formatted
 * @return {string} The human readable formatted money
 */
export const formatAsMoneyString = (money) => {
  // Ensure that the money is correctly parsed as a number
  const parsedMoney = parseFormattedMoney(money);

  return getFormatter().format(parsedMoney);
};

/**
 * Parses and returns the formatted money string as a valid {@link Number}
 *
 * @param {string | number} money Money string to convert to a number
 * @return {number} The money as a float Number
 */
export const parseFormattedMoney = (money) => {
  const sanitizedMoney = sanitizeMoney(money);

  return parseFloat(sanitizedMoney);
};

/**
 * Sanitizes a money string removing any currency or grouping symbols.
 * Or if it's already a number, returns it as string.
 *
 * @param {string | number} money Money string to sanitize
 * @return {string}
 */
const sanitizeMoney = (money) => {
  if (typeof money === 'number') {
    return money.toString();
  }

  return money.replace(/[^0-9.-]+/g, '');
};

/**
 * Returns a initialized {@link Intl.NumberFormat} to format en-GB money.
 *
 * @return {Intl.NumberFormat}
 */
const getFormatter = () => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
  });
};
