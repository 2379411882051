import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="copy-action"
export default class extends Controller {
  static targets = ['source', 'message'];

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.value);
    this.messageTarget.classList.remove('d-none');
    this.hideMessage(this.messageTarget.classList);
  }

  hideMessage(classList) {
    setTimeout(function () {
      classList.add('d-none');
    }, 5000);
  }
}
