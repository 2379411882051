import $ from 'jquery';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dropdown', 'dropdownItem'];
  static values = {
    categoryId: String,
  };

  /**
   * Add click event to 'Set all' dropdown items to change
   * the acceptance status for all items in the list.
   */
  dropdownItemTargetConnected(target) {
    const acceptanceStatus = target.dataset.acceptance;
    target.addEventListener('click', () => {
      this.#setAllCategoriesApprovalsTo(acceptanceStatus);
    });
  }

  #setAllCategoriesApprovalsTo(acceptanceStatus) {
    const rememberAcceptanceStatusSummary =
      this.#rememberAcceptanceStatusSummary.bind(this);

    // We only want to apply the change to those that are visible in case the user is filtering
    // and to those child records of the parent (record-[id])
    $(`tr.collapsed-by-${this.categoryIdValue}`)
      .filter(':visible')
      .each((index, item) => {
        // find the checkbox to apply directly from the item
        const inputElement = item.querySelector(
          `input.btn-check[value="${acceptanceStatus}"]`
        );
        if (inputElement && !inputElement.disabled) {
          inputElement.checked = true;

          const recordId = inputElement.dataset.recordId;
          rememberAcceptanceStatusSummary(acceptanceStatus, recordId);
        }
      });
  }

  #rememberAcceptanceStatusSummary(acceptanceStatus, recordId) {
    this.dispatch('rememberStatusChange', {
      prefix: 'acceptance-status-summary',
      detail: { acceptanceStatus, recordId },
    });
  }
}
