export const LOCATION_ERROR_PERMISSION_DENIED_MESSAGE =
  'To find your current location, Enni requires location permissions to be allowed.\n\n' +
  "Please check your browser's address bar for location permission and try again.";

export const LOCATION_ERROR_OTHER_MESSAGE =
  'We were unable to find your current location. Please try again.';

/**
 * Handles geolocation position error on `Geolocation.getCurrentPosition()`.
 *
 * @param {GeolocationPositionError} error
 * @param {boolean} shouldAlert - Whether or not to show an alert dialog to the user. Defaults to `true
 * @see https://w3c.github.io/geolocation-api/#dom-geolocationpositionerror
 */
export const handleGeolocationError = (error, shouldAlert = true) => {
  switch (error.code) {
    case window.GeolocationPositionError.PERMISSION_DENIED:
      // Request a position failed because the user denied permission to use the API.
      // Note: Browser permission VS. OS permission
      //       On certain platforms, there can be a circumstance where the user has granted
      //       the user agent permission to use Geolocation at the browser-level,
      //       but the permission to access location services has been denied at the OS level.
      if (shouldAlert) {
        window.dispatchEvent(
          new CustomEvent('alert-modal:showModal', {
            detail: {
              icon: 'bi-geo-alt-fill',
              message: LOCATION_ERROR_PERMISSION_DENIED_MESSAGE,
            },
          })
        );
      }
      break;

    case window.GeolocationPositionError.TIMEOUT:
    case window.GeolocationPositionError.POSITION_UNAVAILABLE:
    default:
      // timeout: The length of time specified by the timeout member has elapsed before the user agent
      //          could successfully acquire a position.
      // position_unavailable: Data acquisition error any other reason
      if (shouldAlert) {
        window.dispatchEvent(
          new CustomEvent('alert-modal:showModal', {
            detail: {
              icon: 'bi-geo-alt-fill',
              message: LOCATION_ERROR_OTHER_MESSAGE,
            },
          })
        );
      }
      console.warn(error);
      break;
  }
};
