import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['form'];

  connect() {
    this.formTarget.addEventListener('turbo:submit-end', (event) => {
      this.next(event);
    });
  }

  next(event) {
    if (
      event.detail.success &&
      event.detail.fetchResponse.response.redirected
    ) {
      Turbo.visit(event.detail.fetchResponse.response.url);
    }
  }

  onFilesChange() {
    this.submit();
  }

  removeDocument(e) {
    $(e.target).parents('[data-safeguarding-policy-document]').remove();
  }

  submit() {
    Rails.fire(this.formTarget, 'submit');
  }
}
