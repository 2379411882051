import { Controller } from '@hotwired/stimulus';
import DatePicker from 'tui-date-picker';

// Connects to data-controller="routine--reports-filter"
export default class extends Controller {
  static targets = ['venuesInput'];

  connect() {
    this.initSelect2(this.venuesInputTarget);
  }

  initTimePicker(target) {
    return new DatePicker($(target).data('container'), {
      ...(target.value && { date: Date.parse(target.value) }),
      input: {
        element: target,
        format: 'D, dd MMM YYYY',
      },
      weekStartDay: 'Mon',
    });
  }

  initSelect2(target) {
    $(target).select2({
      placeholder: $(target).attr('placeholder'),
      closeOnSelect: false,
    });
  }

  selectAllVenues() {
    $(this.venuesInputTarget).find('option').prop('selected', 'selected');
    $(this.venuesInputTarget).trigger('change');
  }
}
