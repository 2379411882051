import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['statusFields'];

  toggleOption(e) {
    const option = e.target.value;
    const fieldset = $(this.element).find(`#${option}-fields`);

    this.toggleFieldset(fieldset);
  }

  toggleFieldset(currentFieldset) {
    $(this.statusFieldsTargets).prop('disabled', true);
    currentFieldset.prop('disabled', false);
  }
}
