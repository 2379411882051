import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="customer-specific-price-date"
export default class extends Controller {
  static targets = ['dateInput', 'destroyInput', 'container'];

  static values = {
    firstBookingDate: String,
  };

  initialize() {
    if (!this.dateInputTarget.value) {
      this.setDateToNow();
    }
  }

  setDateToNow() {
    this.dateInputTarget.valueAsDate = new Date();
  }

  setDateToFirstBooking() {
    this.dateInputTarget.value = this.firstBookingDateValue;
  }

  remove() {
    this.destroyInputTarget.checked = true;
    this.containerTarget.classList.add('d-none');
  }
}
