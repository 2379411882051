import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import DatePicker from 'tui-date-picker';
import moment from 'moment/moment';

// Connects to data-controller="simple-time-picker"
export default class extends Controller {
  static targets = [
    'datePicker',
    'endDate',
    'startTime',
    'endTime',
    'frame',
    'timeButton',
    'dateTrigger',
  ];
  static values = {
    startTimeUrl: String,
    endTimeUrl: String,
    advancedFilter: false,
    simpleSearch: false,
    submitEventValue: String,
  };

  connect() {
    if (this.simpleSearchValue) {
      this.datePicker = this.initDatePicker();
      this.addDatePickerEventListeners();
      this.addDateTriggerEventListener();
      setTimeout(() => {
        this.populateDateTriggerLabel();
      }, 100); // handles date fields retaining previous values on back browser navigation
    }
    const boundHandleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener('click', boundHandleClickOutside);
  }

  datePickerClosed() {
    const date = moment(this.datePicker.getDate());
    if (date.isValid()) {
      this.showStartTimePicker();
      this.endDateTarget.value = date;
    }
    this.populateDateTriggerLabel();
  }

  populateDateTriggerLabel() {
    if (this.simpleSearchValue && this.datePickerTarget.value) {
      this.dateTriggerTarget.text = `${this.datePickerTarget.value}`;
      if (this.startTimeTarget.value && this.endTimeTarget.value) {
        this.dateTriggerTarget.text += ` ${this.startTimeTarget.value} to ${this.endTimeTarget.value}`;
      }
    }
  }

  initDatePicker() {
    const target = this.datePickerTarget;
    const rangeStart = moment();
    const rangeEnd = moment().add(18, 'months');
    const datePicker = new DatePicker($(target).data('container'), {
      ...(target.value && { date: Date.parse(target.value) }),
      input: {
        element: target,
        format: 'D, dd MMM YYYY',
      },
      calendar: {
        showToday: false,
      },
      weekStartDay: 'Mon',
      selectableRanges: [[rangeStart, rangeEnd]],
    });

    datePicker.on('change', () => {
      const event = new Event('change', { bubbles: true });
      target.dispatchEvent(event);
    });

    datePicker.on('close', () => {
      const event = new Event('close', { bubbles: true });
      target.dispatchEvent(event);
    });

    return datePicker;
  }

  addDateTriggerEventListener() {
    const boundShowStartDatePicker = this.showStartDatePicker.bind(this);
    this.dateTriggerTarget.addEventListener('click', boundShowStartDatePicker);
  }

  addDatePickerEventListeners() {
    const boundDatePickerClosed = this.datePickerClosed.bind(this);
    $(this.datePickerTarget).on('close', boundDatePickerClosed);
  }

  showStartDatePicker(e) {
    e.preventDefault();
    this.datePicker.open();
  }

  showStartTimePicker() {
    this.frameTarget.src = this.startTimeUrlValue;
  }

  hideTimePicker() {
    if (this.hasEndTimeTarget) {
      this.frameTarget.innerHTML = '';
      if (!this.endTimeTarget.value) {
        this.clearStartTimeInput();
      }
    }
  }

  clearStartTimeInput() {
    if (this.simpleSearchValue) {
      this.startTimeTarget.value = '';
    }
  }

  setStartTime(e) {
    const startTime = e.target.dataset.value || null;
    this.simpleSearchValue
      ? this.showEndTimePicker(startTime)
      : this.hideTimePicker();
    this.startTimeTarget.value = startTime;
    if (this.simpleSearchValue) {
      this.populateDateTriggerLabel();
    }
    this.dispatchEvent('time_picker:time_set', e);
  }

  setEndTime(e) {
    const endTime = e.target.dataset.value || null;
    this.endTimeTarget.value = endTime;
    this.hideTimePicker();
    this.populateDateTriggerLabel();
    this.dispatchEvent('time_picker:time_set', e);
  }

  showEndTimePicker() {
    this.boundEndTimeLoaded = this.endTimeLoaded.bind(this);
    this.frameTarget.addEventListener(
      'turbo:frame-load',
      this.boundEndTimeLoaded
    );
    this.frameTarget.src = this.endTimeUrlValue;
  }

  endTimeLoaded() {
    this.frameTarget.removeEventListener(
      'turbo:frame-load',
      this.boundEndTimeLoaded
    );
    const startTime = this.startTimeTarget.value;
    const format = 'HH:mm';
    const momentStartTime = moment(startTime, format);

    this.timeButtonTargets.forEach((timeButton) => {
      if (!timeButton.dataset.value) {
        return;
      }
      const momentEndTime = moment(timeButton.dataset.value, format);
      if (!momentEndTime.isAfter(momentStartTime)) {
        timeButton.disabled = true;
      }
    });
  }

  handleClickOutside(event) {
    if (this.hasFrameTarget && !this.frameTarget.contains(event.target)) {
      this.hideTimePicker();
    }
  }

  dispatchEvent(name) {
    const event = new CustomEvent(name);
    window.dispatchEvent(event);
  }
}
