import $ from 'jquery';
import { Controller } from '@hotwired/stimulus';
import _ from 'lodash-contrib';

export default class extends Controller {
  static targets = ['input'];

  initialize() {
    Promise.resolve().then(() => {
      const select2Options = $(this.inputTarget).data('select2').options
        .options;

      $(this.inputTarget).select2(
        _.merge(select2Options, {
          ajax: {
            processResults: function (response) {
              response.data.forEach((resource) => {
                resource.text =
                  resource.attributes.text || resource.attributes.name;
                resource.id = JSON.stringify({
                  type: resource.type,
                  id: resource.id,
                });
              });

              return {
                results: response.data,
                pagination: {
                  more: response.links?.next,
                },
              };
            },
          },
        })
      );
    });
  }
}
