import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="mobile-footer"
export default class extends Controller {
  static targets = ['footer'];
  connect() {
    if (this.hasFooterTarget) {
      this.resizeObserver.observe(this.footerTarget);
    }
  }

  resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      this.addPaddingEqualToFooter(entry.contentRect.height);
    }
  });

  addPaddingEqualToFooter(footerHeight) {
    this.element.style.paddingBottom = `${footerHeight}px`;
  }
}
