import CalendarController from './calendar_controller';
import moment from 'moment';
import $ from 'jquery';
import { createPopper } from '@popperjs/core';
import { Turbo } from '@hotwired/turbo-rails';

// Connects to data-controller="opening-hours-calendar"
export default class extends CalendarController {
  static values = {
    schedulesUrl: String,
    newModalPath: String,
    readonly: Boolean,
    readonlyModalPath: String,
  };

  static targets = ['rightClickTooltip', 'tooltipDestroyBookingForm'];

  initialize() {
    this.setCalendarOptions();
    this.initCalendar();
    this.getSchedulesRequest(this.schedulesUrlValue);
    this.submitEndEventListener();
  }

  submitEndEventListener() {
    document.addEventListener('turbo:submit-end', (e) => {
      if (
        e.detail.success &&
        (e.target.id.includes('opening_hours') ||
          e.target.id.includes('repeat_options'))
      )
        this.getSchedulesRequest();
    });
  }

  setCalendarOptions() {
    this.calendarOptionsValue = {
      ...this.calendarOptionsValue,
      ...this.calendarOptions,
    };
  }

  getSchedulesRequestsResponse(res) {
    if (res['opening_hours'])
      this.loadScheduleRequests(res['opening_hours']['data']);
  }

  clickScheduleEvent(e) {
    if (this.readonlyValue) {
      this.getReadOnlyModal();
      return;
    }

    this.getUpdateOpeningHoursModal(e);
  }

  getReadOnlyModal() {
    $.get(this.readonlyModalPathValue)
      .done(function (html) {
        Turbo.renderStreamMessage(html);
      })
      .fail(function (error) {
        console.log(error.statusText);
      });
  }

  beforeCreateScheduleEvent(e) {
    if (this.readonlyValue) {
      this.getReadOnlyModal();
      e.guide.clearGuideElement();
      return;
    }

    if (e.start.toDate().getTime() >= moment().startOf('day')) {
      this.getOpeningHoursModal(e);
    }
    e.guide.clearGuideElement();
  }

  getOpeningHoursModal(e) {
    $.get(this.newModalPathValue, {
      opening_hours: this.startAndEndTimestampFromCalendarEvent(e),
    })
      .done(function (html) {
        Turbo.renderStreamMessage(html);
      })
      .fail(function (error) {
        console.log(error.statusText);
      });
  }

  getUpdateOpeningHoursModal(e) {
    $.get(e.schedule.raw.showPath, {
      opening_hours: e.start
        ? this.startAndEndTimestampFromCalendarEvent(e)
        : this.startAndEndTimestampFromSchedule(e),
    })
      .done(function (html) {
        Turbo.renderStreamMessage(html);
      })
      .fail(function (error) {
        console.log(error.statusText);
      });
  }

  startAndEndTimestampFromSchedule(e) {
    const { startDate, endDate } = this.startAndEndMomentFromSchedule(e);
    return { start_time: startDate.format('X'), end_time: endDate.format('X') };
  }

  startAndEndMomentFromSchedule(e) {
    const startDate = moment(e.schedule.start.toDate()).set('minute', 0);
    let endDate = moment(e.schedule.end.toDate()).set('minute', 0);
    const duration = moment.duration(endDate.diff(startDate));
    if (duration.asHours() < 2) {
      endDate = startDate.clone().add(1, 'hour');
    }

    return { startDate, endDate };
  }

  beforeUpdateScheduleEvent(e) {
    if (this.readonlyValue) {
      this.getReadOnlyModal();
      return;
    }

    if (e.start.toDate().getTime() >= moment().startOf('day')) {
      this.getUpdateOpeningHoursModal(e);
    }
  }

  rightClickScheduleEvent(e) {
    if (!this.hasRightClickTooltipTarget) return;
    this.renderRightClickPopover(e);
    e.event.target.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    });
  }

  renderShowBookingModal(e) {
    const { startDate, endDate } = e.start
      ? this.startAndEndDateFromCalendarEvent(e)
      : [null, null];
    const data = {
      start_time: startDate,
      end_time: endDate,
    };
    $.get(e.schedule.raw.showPath, data);
  }

  renderRightClickPopover(e) {
    const trigger = e.event.target;
    this.rightClickTooltipTarget.classList.remove('d-none');
    createPopper(trigger, this.rightClickTooltipTarget);
    const hide = () => {
      this.rightClickTooltipTarget.classList.add('d-none');
      document.removeEventListener('click', hide);
    };
    document.addEventListener('click', hide);
  }

  dispatchEvent(name, calendarEvent) {
    const event = new CustomEvent(name, {
      detail: this.startAndEndDateFromCalendarEvent(calendarEvent),
    });
    window.dispatchEvent(event);
  }

  loadScheduleRequests(openingHours) {
    const openingHoursSchedule = this.jsonToCalendarSchedule(
      openingHours,
      'openingHours',
      false
    );

    this.calendar.clear();

    this.calendar.createSchedules([...openingHoursSchedule]);
  }

  jsonToCalendarSchedule(json, calendarId, readOnly = true) {
    return json.map((data) => {
      let bri = data.attributes;
      bri.calendarId = calendarId;
      bri.title = `${this.timeOnly(bri['start-time'])} - ${this.timeOnly(
        bri['end-time']
      )}`;
      bri.category = 'time';
      bri.start = this.stripTimeZone(bri['start-time']);
      bri.end = this.stripTimeZone(bri['end-time']);
      bri.isReadOnly = readOnly;
      bri.raw = {
        showPath: bri['show-path'],
        deletePath: bri['delete-path'],
        updatePath: bri['update-path'],
      };
      return bri;
    });
  }

  calendars = [
    {
      id: 'openingHours',
      name: 'Opening Hours',
      bgColor: 'rgba(81,52,182,0.3)',
      dragBgColor: 'rgba(81,52,182,0.3)',
      borderColor: 'rgba(81,52,182,0.3)',
    },
  ];

  calendarOptions = {
    calendars: this.calendars,
  };
}
