import $ from 'jquery';
import _ from 'lodash';
import { Controller } from '@hotwired/stimulus';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

const debounce = require('debounce');

// Google maps controller to toggle between a list view of
// search and book results or the map.
export default class extends Controller {
  static targets = ['map', 'searchToggle', 'searchSpinner'];
  static values = { items: Array };

  initialize() {
    this._debouncedSearch = debounce(this.searchByBounds, 500);

    if (typeof google != 'undefined') {
      this.initMap();
    }
  }

  // Google maps callback - call on map_target element
  initMap() {
    this.markers = [];

    this.map = new google.maps.Map(this.mapTarget, {
      fullscreenControl: false,
      mapTypeControl: false,
      minZoom: this.minZoom(),
      maxZoom: this.maxZoom(),
      styles: this.styles(),
    });

    this.bounds = new google.maps.LatLngBounds();

    // Simple flag to determine if we've changed the map boundary in any way
    this.boundsChanged = false;

    // Wait for the map to be initialized before adding markers and updating the bounds
    // otherwise we will just end up with a grey map view.
    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      this.addMarkers();
      this.updateBounds();
      this.setupEvents();
    });
  }

  setupEvents() {
    this.dragListener = google.maps.event.addListener(
      this.map,
      'dragend',
      this._debouncedSearch.bind(this)
    );
    this.zoomListener = google.maps.event.addListener(
      this.map,
      'zoom_changed',
      this._debouncedSearch.bind(this)
    );
  }

  minZoom() {
    return 6;
  }

  maxZoom() {
    return 17;
  }

  styles() {
    return [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
    ];
  }

  addMarkers() {
    // Only one info window to ensure we don't have lots opening
    this.infoWindow = new google.maps.InfoWindow();

    // Group the items together by venue_id
    // Sort and cap the data on the backend
    let itemGroup = _.groupBy(this.itemsValue, 'venue_id');

    _.forEach(itemGroup, (groupItem) => {
      let marker = this.addMarker(groupItem);
      this.markers.push(marker);
    });

    // Pin cluster manager
    this.markerClusterer = new MarkerClusterer({
      map: this.map,
      markers: this.markers,
    });
  }

  markerBackgroundColour(availability) {
    // TODO: Rules for availability colour banding
    // to be finalised.
    let colour = 'blue';
    if (availability >= 0.75) {
      colour = 'green';
    } else if (availability >= 0.5) {
      colour = 'orange';
    } else if (availability > 0) {
      colour = 'red';
    }

    return colour;
  }

  // Use bootstrap icon code, but could use any icon code
  // from any font icons we're using.
  markerIconCode(itemCount, item) {
    if (itemCount > 1) return '\uF2C9';
    if (item.instant_book) return '\uF46C';

    return null;
  }

  markerLabelIcon(bootstrapIconCode) {
    if (_.isEmpty(bootstrapIconCode)) return '';

    return {
      text: bootstrapIconCode,
      fontFamily: 'bootstrap-icons',
      color: '#ffffff',
      fontSize: '14px',
    };
  }

  addMarker(groupItem) {
    const item = groupItem[0];

    const latLng = new google.maps.LatLng(item.lat, item.lng);

    const svgMarker = {
      path: 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z',
      fillColor: this.markerBackgroundColour(item.availability),
      fillOpacity: 0.9,
      strokeWeight: 2,
      strokeColor: 'white',
      rotation: 0,
      scale: 1.5,
      anchor: new google.maps.Point(12, 17),
      labelOrigin: new google.maps.Point(12, 10),
    };

    let marker = new google.maps.Marker({
      map: this.map,
      position: latLng,
      title: item.venue_name,
      items: groupItem,
      label: this.markerLabelIcon(this.markerIconCode(groupItem.length, item)),
      icon: svgMarker,
    });

    google.maps.event.addListener(marker, 'click', () => {
      this.infoWindow.setContent(this.infoWindowContent(groupItem));
      this.infoWindow.open({
        anchor: marker,
        map: this.map,
        shouldFocus: false,
      });

      this.map.panTo(marker.getPosition());
    });

    this.bounds.extend(latLng);

    return marker;
  }

  infoWindowContent(items) {
    // Just for now use the first facility item for content.
    // This may change if we want to show each facility.
    const item = items[0];
    let url, linkText;

    if (items.length > 1) {
      url = this.venueUrl(item);
      linkText = 'View venue facilities';
    } else {
      url = this.facilityUrl(item);
      linkText = 'Book venue';
    }

    const address = item.full_address.split(',').join('</br>');
    const contentString =
      '<div class="info-window-header mb-1">' +
      `<div class="fw-bold">${item.venue_name}</div>` +
      '</div>' +
      '<div class="info-window-body mb-1">' +
      `<div class="mb-1">${address}</div>` +
      `<div class="info-window-url"><a href="${url}" class="">${linkText}</a></div>` +
      '</div>';

    return contentString;
  }

  searchUrl() {
    return window.location.origin.concat(window.location.pathname);
  }

  // For a single result to take us to the facility details
  facilityUrl(item) {
    return item.facility_path.concat('/', window.location.search);
  }

  // For multiple results at the same venue to take us to search
  // results for the venue
  venueUrl(item) {
    const params = new URLSearchParams(window.location.search);
    params.set('venue', item.venue_id);
    // Reset the pagination
    params.set('page', 1);
    // We also want to ensure we use the list view
    params.set('bookings_view', 'list');

    return this.searchUrl().concat('?', params);
  }

  updateBounds() {
    if (this.markers.length == 0) {
      this.map.setCenter({ lat: 54.003, lng: -2.548 });
      this.map.setZoom(this.minZoom());
    } else {
      this.map.fitBounds(this.bounds);
    }
  }

  getBounds() {
    const latLngBounds = this.map.getBounds();
    const neBounds = latLngBounds.getNorthEast();
    const swBounds = latLngBounds.getSouthWest();

    return {
      ne: {
        lat: neBounds.lat(),
        lng: neBounds.lng(),
      },
      sw: {
        lat: swBounds.lat(),
        lng: swBounds.lng(),
      },
    };
  }

  searchByBounds() {
    if (!this.searchAsMoveEnabled()) return;

    if (!this.mapVisible()) return;

    this.boundsChanged = true;

    const _this = this;
    const params = new URLSearchParams(window.location.search);
    const latLngCenter = this.map.getCenter();

    // We don't want to search by location
    params.delete('location');

    $(this.searchToggleTarget).hide();
    $(this.searchSpinnerTarget).removeClass('d-none').addClass('d-flex').show();

    $.ajax({
      url: this.searchUrl().concat('.json', '?', params),
      type: 'get',
      data: {
        lat: latLngCenter.lat(),
        lng: latLngCenter.lng(),
        bounds: JSON.stringify(this.getBounds()),
      },
    })
      .done((data) => {
        _this.itemsValue = data;
        _this.deleteMarkers();
        _this.addMarkers();
      })
      .always(() => {
        $(this.searchSpinnerTarget).removeClass('d-flex').hide();
        $(this.searchToggleTarget).show();
      });
  }
  // Tie to action togglePrimary@window->map#toggleToMap if we're using the toggle controller
  toggleToMap() {
    google.maps.event.removeListener(this.dragListener);
    google.maps.event.removeListener(this.zoomListener);
    this.updateBounds();
    this.setupEvents();
  }

  // tie to action toggleAlternate@window->map#toggleToList
  toggleToList() {
    if (!this.boundsChanged) return;

    const latLngCenter = this.map.getCenter();
    const params = new URLSearchParams(window.location.search);
    params.set('bounds', JSON.stringify(this.getBounds()));
    params.set('lat', latLngCenter.lat());
    params.set('lng', latLngCenter.lng());
    params.set('location', 'lookup');

    window.location = this.searchUrl().concat('?', params);
  }

  mapVisible() {
    return $(this.mapTarget).is(':visible');
  }

  deleteMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }

    this.markers = [];
    this.markerClusterer.clearMarkers();
  }

  searchAsMoveEnabled() {
    return $(this.searchToggleTarget)
      .find('input[type="checkbox"]')
      .prop('checked');
  }
}
