import $ from 'jquery';
import Tooltip from 'bootstrap/js/dist/tooltip';
import { Controller } from '@hotwired/stimulus';
import { useSelect2 } from './mixins/useSelect2';

export default class extends Controller {
  static targets = [
    'safeguardingPolicyGroup',
    'childrenAttendingInput',
    'approvableSelect',
  ];

  initialize() {
    useSelect2(this);

    this.initApprovableSelects();

    if (this.hasSafeguardingPolicyGroupTarget) {
      $(this.childrenAttendingInputTargets).change((e) => {
        if (e.target.value === 'true') {
          $(this.safeguardingPolicyGroupTarget).removeClass('d-none');
        } else {
          $(this.safeguardingPolicyGroupTarget).addClass('d-none');
        }
      });
    }

    document.addEventListener('turbo:before-cache', () => {
      this.destroyApprovableSelects();
    });
  }

  initApprovableSelects() {
    this.tooltips = [];

    this.approvableSelectTargets.forEach((select) => {
      $(select).select2({
        templateResult: this.optionTemplate.bind(this),
        placeholder: $(select).attr('placeholder'),
        closeOnSelect: false,
        width: 'style',
      });
    });

    $(this.approvableSelectTargets).on('select2:close', () => {
      this.tooltips.forEach((tooltip) => tooltip.dispose());
      this.tooltips = [];
    });
  }

  destroyApprovableSelects() {
    this.approvableSelectTargets.forEach((inputElement) => {
      this.destroySelect2(inputElement, 'select2:close');
    });
  }

  optionTemplate(type) {
    if (!type.id) {
      return type.text;
    }

    const [$type, tooltip] = this.templateElements(type);

    if (tooltip) {
      this.tooltips.push(tooltip);
    }

    return $type;
  }

  templateElements(type) {
    const $type = $('<span />', { text: type.text });
    let tooltip = null;

    if ($(type.element).data('acceptance-status') === 'closed') {
      const $closedIcon = $('<span />', {
        class: 'bi bi-dash-circle ms-2',
        title: 'Not approved for the chosen facility',
        'data-bs-toggle': 'tooltip',
        'data-bs-placement': 'top',
      });

      tooltip = new Tooltip($closedIcon.get(0));
      $closedIcon.appendTo($type);
    }

    return [$type, tooltip];
  }

  isSelect2(element) {
    return element.classList.contains('select2-hidden-accessible');
  }
}
