import { Controller } from '@hotwired/stimulus';
import { useElementVisibility } from './mixins/useElementVisibility';
import BootstrapModal from 'bootstrap/js/dist/modal';

// Connects to data-controller="alert-modal"
export default class extends Controller {
  static targets = [
    'icon',
    'iconContainer',
    'title',
    'titleContainer',
    'message',
    'messageContainer',
  ];

  static values = {
    icon: String,
    title: String,
    message: String,
  };

  connect() {
    useElementVisibility(this);

    this.modal = new BootstrapModal(this.element);

    this.modalHideHandler = this.onDismiss.bind(this);
    this.element.addEventListener('hidden.bs.modal', this.modalHideHandler);
  }

  disconnect() {
    if (this.modalHideHandler) {
      this.element.removeEventListener(
        'hidden.bs.modal',
        this.modalHideHandler
      );
    }
    this.onDismiss();
  }

  showModal({ detail }) {
    if (!this.modal) {
      console.error(
        'Attempted to show alert modal without it being initialized first.'
      );
      return;
    }

    this.iconValue = detail.icon?.trim();
    this.titleValue = detail.title?.trim();
    this.messageValue = detail.message?.trim();

    this.onShow();
  }

  onShow() {
    if (!this.titleValue && !this.messageValue) {
      // Show nothing, as no title, nor message is set.
      return;
    }

    if (this.iconValue) {
      this.iconTarget.classList.add('bi', this.iconValue);
    } else {
      this.iconTarget.className = '';
    }
    this.setElementVisible(this.iconTarget, !!this.iconValue);

    this.titleTarget.innerText = this.titleValue;
    this.setElementVisible(this.titleContainerTarget, !!this.titleValue);

    this.messageTarget.innerText = this.messageValue;
    this.setElementVisible(this.messageContainerTarget, !!this.messageValue);

    this.modal?.show();
  }

  onDismiss() {
    // Clear values so its ready for the next show.
    this.iconValue = '';
    this.iconTarget.className = '';

    this.titleValue = '';
    this.titleTarget.innerText = this.titleValue;

    this.messageValue = '';
    this.messageTarget.innerText = this.messageValue;
  }
}
