// Connects to data-controller="bookings-approvals-safeguarding-form"
import { Controller } from '@hotwired/stimulus';
import { useElementVisibility } from '../mixins/useElementVisibility';

export default class extends Controller {
  static targets = ['reviewLink', 'proceedAfterSafeguardingReviewButton'];

  connect() {
    useElementVisibility(this);
  }

  /**
   * @param {HTMLAnchorElement} target
   */
  reviewLinkTargetConnected(target) {
    target.addEventListener('click', this.#onReviewLinkClick.bind(this));
  }

  #onReviewLinkClick() {
    this.setElementVisible(this.proceedAfterSafeguardingReviewButtonTarget);
  }
}
