import { Controller } from '@hotwired/stimulus';
import { useElementEnabled } from '../mixins/useElementEnabled';

// Connects to data-controller="bookings-approvals-reason-modal"
export default class extends Controller {
  static targets = [
    'title',
    'manualApprovalReasonHelpText',
    'reasonTextAreaLabel',
    'reasonTextArea',
    'confirmButton',
  ];

  static values = {
    bookingInstanceId: String,
    facilityId: String,
    decision: String,
  };

  connect() {
    useElementEnabled(this);

    this.reasonTextAreaChangeHandler = this.#onReasonTextAreaChange.bind(this);
    this.reasonTextAreaTarget.addEventListener(
      'input',
      this.reasonTextAreaChangeHandler
    );
    this.#onReasonTextAreaChange(); // set initial button state (active/disabled)
  }

  disconnect() {
    if (this.reasonTextAreaChangeHandler) {
      this.reasonTextAreaTarget.removeEventListener(
        'input',
        this.reasonTextAreaChangeHandler
      );
    }
  }

  onConfirm(event) {
    const { decision, bookingInstanceId, facilityId } = event.params;
    this.dispatch('onConfirm', {
      prefix: 'bookings-approvals-reason-modal',
      detail: {
        reason: this.reasonTextAreaTarget.value,
        decision: decision,
        bookingInstanceId: `${bookingInstanceId}`,
        facilityId: `${facilityId}`,
      },
    });
  }

  #onReasonTextAreaChange() {
    // Disable the confirm button unless a valid reason is provided.
    this.setElementEnabled(this.confirmButtonTarget, this.#validate());
  }

  /**
   * Validates the modal
   *
   * @return {boolean} `true` if valid, otherwise `false`.
   */
  #validate() {
    return this.reasonTextAreaTarget.checkValidity();
  }
}
