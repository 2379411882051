import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import { Turbo } from '@hotwired/turbo-rails';
import moment from 'moment/moment';
import DatePicker from 'tui-date-picker';

// Connects to data-controller="opening-hours-form"
export default class extends Controller {
  static targets = [
    'startTime',
    'endTime',
    'genericDatePicker',
    'date',
    'toDate',
    'timePickerWrapper',
    'endOfDayToggle',
    'midnightLabel',
  ];

  static values = {
    repeatFormModalPath: String,
    singleFormModalPath: String,
  };

  connect() {
    if (this.hasGenericDatePickerTarget) {
      this.genericDatePickerTargets.forEach((dateTarget) => {
        this.initDatePicker(dateTarget);
      });
    }

    this.toggleEndTimePicker(this.endOfDayToggleTarget.checked);
  }

  initDatePicker(target) {
    const rangeStart = moment();
    const rangeEnd = moment().add(18, 'months');

    const datePicker = new DatePicker($(target).data('container'), {
      ...(target.value && { date: Date.parse(target.value) }),
      input: {
        element: target,
        format: 'D, dd MMM YYYY',
      },
      calendar: {
        showToday: false,
      },
      weekStartDay: 'Mon',
      selectableRanges: [[rangeStart, rangeEnd]],
    });

    datePicker.on('change', () => {
      const event = new Event('change', { bubbles: true });
      target.dispatchEvent(event);
    });

    return datePicker;
  }

  getSingleFormModal() {
    this.switchModal('opening_hours', this.singleFormModalPathValue);
  }

  getRepeatFormModal() {
    this.switchModal('repeat_options', this.repeatFormModalPathValue);
  }

  switchModal(paramKey, path) {
    let startTimeValue = moment(this.startTimeTarget.value || '00:00', 'HH:mm');
    let endTimeValue = moment(
      this.endTimeTarget.value || startTimeValue,
      'HH:mm'
    );
    const date = new Date(this.dateTarget.value);
    let startTime = moment(date);
    startTime.set('hours', startTimeValue.hours());
    startTime.set('minutes', startTimeValue.minutes());
    let endTime = moment(date);
    endTime.set('hours', endTimeValue.hours());
    endTime.set('minutes', endTimeValue.minutes());
    let params = {};
    params[paramKey] = {
      start_time: startTime.unix(),
      end_time: endTime.unix(),
    };

    $.get(path, params)
      .done(function (html) {
        Turbo.renderStreamMessage(html);
      })
      .fail(function (error) {
        console.log(error.statusText);
      });
  }

  indefiniteToggle(e) {
    if (e.target.checked) {
      this.toDateTarget.classList.add('disabled');
      this.toDateTarget.setAttribute('disabled', '');
      this.toDateTarget.value = '';
    } else {
      this.toDateTarget.classList.remove('disabled');
      this.toDateTarget.removeAttribute('disabled');
      this.initDatePicker(this.toDateTarget);
    }
  }

  endOfDayToggle(e) {
    this.toggleEndTimePicker(e.currentTarget.checked);
  }

  endOfDayToggleOff() {
    this.endOfDayToggleTarget.checked = false;
    this.toggleEndTimePicker(false);
  }

  toggleEndTimePicker(active) {
    const klass = 'd-none';

    if (active) {
      this.midnightLabelTarget.classList.remove(klass);
      this.endTimeTarget.classList.add(klass);
    } else {
      this.endTimeTarget.classList.remove(klass);
      this.midnightLabelTarget.classList.add(klass);
    }
  }
}
