import $ from 'jquery';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['optionFields', 'organisationTypeFields'];

  toggleOption(e) {
    const option = e.target.value;
    const fieldset = $(this.element).find(`#${option}_fields`);

    this.toggleFieldset(this.optionFieldsTargets, fieldset);
  }

  organisationTypeChange(e) {
    const type = e.target.value;
    const fieldset = $(this.element).find(`#${type}_fields`);

    this.toggleFieldset(this.organisationTypeFieldsTargets, fieldset);
  }

  toggleFieldset(targets, fieldset) {
    $(targets).prop('disabled', true).addClass('d-none');
    fieldset.prop('disabled', false).removeClass('d-none');
  }
}
