import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="stretch-link"
export default class extends Controller {
  static values = {
    path: String,
  };
  followLinkPath(e) {
    if (e.target.closest('a')) return;
    window.location.href = this.pathValue;
  }
}
