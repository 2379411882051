import $ from 'jquery';
import { Controller } from '@hotwired/stimulus';

const debounce = require('debounce');

export default class extends Controller {
  static targets = ['input', 'button'];
  static values = {
    itemList: String,
  };

  initialize() {
    this.setupEvents();
  }

  setupEvents() {
    const $inputTarget = $(this.inputTarget);
    const $buttonTarget = $(this.buttonTarget);

    $inputTarget.on(
      'keyup',
      debounce(() => {
        this.search();
      }, 350)
    );

    $inputTarget.on('keydown', (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });

    $inputTarget.on('search', () => {
      this.search();
    });

    $buttonTarget.click(() => {
      this.search();
    });
  }

  search() {
    const query = $(this.inputTarget).val().toLowerCase();
    const $itemList = $(this.itemListValue);

    $itemList.each(function (index, item) {
      const $item = $(item);

      const matches = function (item) {
        const name = item.find('td').eq(0).text().toLowerCase();
        const match = name.includes(query);

        if (match) return true;

        return false;
      };

      if (matches($item)) {
        $item.show();
      } else {
        $item.hide();
      }
    });
  }
}
