import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="diary-entry"
export default class extends Controller {
  static values = {
    accidentReport: Array,
    safeguardingReport: String,
  };

  static targets = [
    'accidentFieldWrapper',
    'accidentField',
    'typeField',
    'groupNameField',
    'unknownGroupInput',
    'childInvolvedFieldWrapper',
    'childInvolvedField',
  ];

  connect() {
    this.handleReportType(this.typeFieldTarget.value);
  }

  typeChange(e) {
    const selectedType = e.target.value;
    this.handleReportType(selectedType);
    this.resetValidation();
  }

  toggleGroupNameUnknown(e) {
    if (e.target.checked) {
      this.groupNameFieldTarget.value = 'Unknown';
      this.groupNameFieldTarget.disabled = true;
    } else {
      this.groupNameFieldTarget.value = '';
      this.groupNameFieldTarget.disabled = false;
    }
  }

  handleReportType(type) {
    if (this.isAccidentReportType(type)) {
      this.enableAccidentFields();
      this.enableChildInvolvedField();
    } else if (this.isSafeguardingReportType(type)) {
      this.enableChildInvolvedField();
      this.disableAccidentFields();
    } else {
      this.disableAccidentFields();
      this.disableChildInvolvedField();
    }
  }

  isAccidentReportType(type) {
    return this.accidentReportValue.includes(type);
  }

  isSafeguardingReportType(type) {
    return this.safeguardingReportValue === type;
  }

  enableChildInvolvedField() {
    this.childInvolvedFieldWrapperTarget.classList.remove('d-none');
    this.childInvolvedFieldTarget.disabled = false;
  }

  disableChildInvolvedField() {
    this.childInvolvedFieldWrapperTarget.classList.add('d-none');
    this.childInvolvedFieldTarget.disabled = true;
  }

  enableAccidentFields() {
    this.accidentFieldWrapperTargets.forEach((target) => {
      target.classList.remove('d-none');
    });
    this.accidentFieldTargets.forEach((target) => {
      if (
        this.unknownGroupInputTarget.checked === true &&
        target === this.groupNameFieldTarget
      ) {
        target.disabled = true;
      } else {
        target.disabled = false;
      }
    });
  }

  disableAccidentFields() {
    this.accidentFieldWrapperTargets.forEach((target) => {
      target.classList.add('d-none');
    });
    this.accidentFieldTargets.forEach((target) => {
      target.disabled = true;
    });
  }

  resetValidation() {
    [
      ...Array.from(document.querySelectorAll('.form-control')),
      ...Array.from(document.querySelectorAll('.form-check-input')),
    ].forEach((el) => el.classList.remove('is-valid', 'is-invalid'));
    [
      ...Array.from(document.querySelectorAll('.invalid-feedback')),
      ...Array.from(document.querySelectorAll('.alert')),
    ].forEach((el) => el.remove());
  }
}
