import $ from 'jquery';
import Rails from '@rails/ujs';
import { Controller } from '@hotwired/stimulus';
import { useElementVisibility } from './mixins/useElementVisibility';

export default class extends Controller {
  static targets = [
    'instalmentsOption',
    'priceBreakdownFrame',
    'paymentMethodsFrame',
    'useNewPaymentMethod',
    'useExistingPaymentMethod',
    'newPaymentMethodElementFrame',
    'existingPaymentMethods',
    'reset',
    'paymentAction',
  ];
  static values = {
    pricePath: String,
    spinner: String,
  };

  connect() {
    useElementVisibility(this);

    if (this.hasPaymentMethodsFrameTarget) {
      $(this.element).on('turbo:before-fetch-request', (event) => {
        if (
          event.target === this.paymentMethodsFrameTarget ||
          event.target === this.priceBreakdownFrameTarget
        ) {
          event.target.innerHTML = this.spinnerValue;
        }
      });
    }
  }

  removePaymentTargets() {
    this.paymentActionTargets.forEach((target) => {
      this.setElementVisible(target, false);
    });
  }

  enablePaymentTargets() {
    this.paymentActionTargets.forEach((target) => {
      this.setElementVisible(target, true);
    });
  }

  toggleInstalmentOption(e) {
    Rails.fire(window, 'payment:instalmentsoption:selected', {
      instalments: e.target.value === 'true',
    });

    this.fetchPriceBreakdown(e.target.value);
    this.paymentMethodsFrameTarget.src = e.target.dataset.methodsPath;
  }

  resetInstalmentOptions() {
    if ($(this.instalmentsOptionTargets).find(':checked').val() === 'true') {
      this.fetchPriceBreakdown(false);
    }
    if (!this.hasPaymentMethodsFrameTarget) return;

    Rails.fire(window, 'payment:instalmentsoption:reset');

    this.paymentMethodsFrameTarget.innerHTML = '';

    $(this.instalmentsOptionTargets)
      .prop('disabled', false)
      .removeClass('d-none')
      .each((_index, elem) => {
        $(elem).find('input[type="radio"]').prop('checked', false);
      });
    $(this.resetTarget).addClass('d-none');
    this.enablePaymentTargets();
  }

  fetchPriceBreakdown(payByInstalments) {
    const path = `${this.pricePathValue}?pay_by_instalments=${payByInstalments}`;
    this.priceBreakdownFrameTargets.forEach((target) => {
      target.src = path;
    });
  }

  useNewPaymentMethod() {
    this.setElementVisible(this.useNewPaymentMethodTarget, false);
    this.setElementVisible(this.existingPaymentMethodsTarget, false);
    this.setElementVisible(this.useExistingPaymentMethodTarget, true);

    this.newPaymentMethodElementFrameTarget.src =
      this.newPaymentMethodElementFrameTarget.dataset.elementPath;
  }

  useExistingPaymentMethod() {
    this.setElementVisible(this.useExistingPaymentMethodTarget, false);
    this.setElementVisible(this.existingPaymentMethodsTarget, true);
    this.setElementVisible(this.useNewPaymentMethodTarget, true);
    this.newPaymentMethodElementFrameTarget.innerHTML = '';
  }

  toggleFieldset(targets, fieldset) {
    $(targets).addClass('d-none');
    fieldset.removeClass('d-none');
  }
}
