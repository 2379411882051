import { Controller } from '@hotwired/stimulus';

const debounce = require('debounce');

// Connects to data-controller="facility-vat"
export default class extends Controller {
  static targets = ['netRateInput', 'grossRateInput'];

  static values = {
    rate: Number,
  };

  initialize() {
    this.netRateInputTarget.addEventListener(
      'keyup',
      debounce(() => {
        this.changeGross();
      }, 350)
    );

    this.netRateInputTarget.addEventListener(
      'change',
      this.changeGross.bind(this)
    );

    this.grossRateInputTarget.addEventListener(
      'keyup',
      debounce(() => {
        this.changeNet();
      }, 350)
    );

    this.grossRateInputTarget.addEventListener(
      'change',
      this.changeNet.bind(this)
    );
  }

  changeGross() {
    this.grossRateInputTarget.value = (
      this.netRateInputTarget.value * this.rateModifier()
    ).toFixed(2);
  }

  changeNet() {
    this.netRateInputTarget.value = (
      this.grossRateInputTarget.value / this.rateModifier()
    ).toFixed(2);
  }

  rateModifier() {
    return this.rateValue / 100 + 1;
  }
}
