import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

// Connects to data-controller="new-customer-specific-price"
export default class extends Controller {
  static targets = ['name', 'email', 'organisations', 'organisationBadges'];
  onCustomerChange(e) {
    const customerData = $(e.target).select2('data');
    if (customerData.length) {
      const {
        'full-name': fullName,
        email,
        organisations,
      } = customerData[0].attributes;
      this.nameTarget.value = fullName;
      this.emailTarget.value = email;
      this.organisationsTarget.value = organisations;
      this.formatInputAsBadges();
    }
  }

  formatInputAsBadges() {
    this.organisationBadgesTarget.innerHTML = '';
    const tags = this.organisationsTarget.value.split(',');
    tags.forEach((tag) => {
      const badge = document.createElement('span');
      badge.className = 'badge bg-primary me-1';
      badge.textContent = tag.trim();
      this.organisationBadgesTarget.appendChild(badge);
    });
  }
}
