import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="dashboard"
export default class extends Controller {
  static targets = [
    'revenueGraphFrame',
    'revenueGraphLoadingIndicator',

    'paymentGraphFrame',
    'paymentGraphLoadingIndicator',

    'utilisationGraphFrame',
    'utilisationGraphLoadingIndicator',

    'socialImpactGraphFrame',
    'socialImpactGraphLoadingIndicator',

    'participationGraphFrame',
    'participationGraphLoadingIndicator',

    'checkInOnTimeGraphFrame',
    'checkInOnTimeGraphLoadingIndicator',

    'tasksOnTimeGraphFrame',
    'tasksOnTimeGraphLoadingIndicator',
  ];

  // Types
  declare readonly revenueGraphFrameTarget: any; // <turbo-frame /> element
  declare readonly revenueGraphLoadingIndicatorTarget: HTMLDivElement;

  declare readonly paymentGraphFrameTarget: any; // <turbo-frame /> element
  declare readonly paymentGraphLoadingIndicatorTarget: HTMLDivElement;

  declare readonly utilisationGraphFrameTarget: any; // <turbo-frame /> element
  declare readonly utilisationGraphLoadingIndicatorTarget: HTMLDivElement;

  declare readonly socialImpactGraphFrameTarget: any; // <turbo-frame /> element
  declare readonly socialImpactGraphLoadingIndicatorTarget: HTMLDivElement;

  declare readonly participationGraphFrameTarget: any; // <turbo-frame /> element
  declare readonly participationGraphLoadingIndicatorTarget: HTMLDivElement;

  declare readonly checkInOnTimeGraphFrameTarget: any; // <turbo-frame /> element
  declare readonly checkInOnTimeGraphLoadingIndicatorTarget: HTMLDivElement;

  declare readonly tasksOnTimeGraphFrameTarget: any; // <turbo-frame /> element
  declare readonly tasksOnTimeGraphLoadingIndicatorTarget: HTMLDivElement;

  // Custom properties and methods
  /**
   * Keeps track of event loading event listener handlers so that they can be
   * aborted when needed.
   */
  private loadingHandlerRef: { [refName: string]: () => void | undefined } = {};
  private rememberedGlobalFilters = {};

  connect() {
    super.connect();
  }

  public rememberedFiltersChange(event: CustomEvent) {
    this.rememberedGlobalFilters = event.detail.selectedFilters;
  }

  public reloadSpecificGraphOnFilterChange(event: CustomEvent) {
    const graphName = event.detail.graphName;
    const selectedFilters: {
      grouping: 'date' | 'activity';
    } = event.detail.selectedFilters;

    // Apply graph specific params alongside the global ones
    const params = this.createParamsFor(this.rememberedGlobalFilters);
    params.append('grouping', selectedFilters.grouping);

    if (graphName === 'social_impact') {
      this.reloadGraphFrame(
        this.socialImpactGraphFrameTarget,
        this.socialImpactGraphLoadingIndicatorTarget,
        'socialImpactGraphLoadingHandler',
        params
      );
    } else if (graphName === 'participation') {
      this.reloadGraphFrame(
        this.participationGraphFrameTarget,
        this.participationGraphLoadingIndicatorTarget,
        'participationGraphLoadingHandler',
        params
      );
    }
  }

  public reloadGraphsOnFilterChange(event: CustomEvent) {
    const selectedFilters: {
      startDate: Date;
      endDate: Date;
      venueIds: string[];
      facilityIds: string[];
      facilityTypeCategoryIds: string[];
      activityCategoryIds: string[];
      customerIds: string[];
      compare: boolean;
    } = event.detail.selectedFilters;
    this.rememberedGlobalFilters = selectedFilters;

    const params = this.createParamsFor(selectedFilters);

    // Update the src, ensuring the latest selected filters are being used
    this.reloadGraphFrame(
      this.revenueGraphFrameTarget,
      this.revenueGraphLoadingIndicatorTarget,
      'revenueGraphLoadingHandler',
      params
    );

    this.reloadGraphFrame(
      this.paymentGraphFrameTarget,
      this.paymentGraphLoadingIndicatorTarget,
      'paymentGraphLoadingHandler',
      params
    );

    this.reloadGraphFrame(
      this.utilisationGraphFrameTarget,
      this.utilisationGraphLoadingIndicatorTarget,
      'utilisationGraphLoadingHandler',
      params
    );

    this.reloadGraphFrame(
      this.socialImpactGraphFrameTarget,
      this.socialImpactGraphLoadingIndicatorTarget,
      'socialImpactGraphLoadingHandler',
      params
    );

    this.reloadGraphFrame(
      this.participationGraphFrameTarget,
      this.participationGraphLoadingIndicatorTarget,
      'participationGraphLoadingHandler',
      params
    );

    this.reloadGraphFrame(
      this.checkInOnTimeGraphFrameTarget,
      this.checkInOnTimeGraphLoadingIndicatorTarget,
      'checkInOnTimeGraphLoadingHandler',
      params
    );

    this.reloadGraphFrame(
      this.tasksOnTimeGraphFrameTarget,
      this.tasksOnTimeGraphLoadingIndicatorTarget,
      'tasksOnTimeGraphLoadingHandler',
      params
    );
  }

  private reloadGraphFrame(
    frameElement: any,
    loadingIndicatorElement: HTMLDivElement,
    loadingHandlerRefName: string,
    filterParams: URLSearchParams
  ) {
    // Abort previous loading handler if there is one
    if (this.loadingHandlerRef[loadingHandlerRefName]) {
      frameElement.removeEventListener(
        'turbo:frame-load',
        this.loadingHandlerRef[loadingHandlerRefName]
      );
    }

    // Display the loading indicator while fetching for content
    frameElement.classList.add('d-none');
    loadingIndicatorElement.classList.remove('d-none');

    // Setup event to display the content after its loaded
    this.loadingHandlerRef[loadingHandlerRefName] = () => {
      loadingIndicatorElement.classList.add('d-none');
      frameElement.classList.remove('d-none');
    };
    frameElement.addEventListener(
      'turbo:frame-load',
      this.loadingHandlerRef[loadingHandlerRefName]
    );

    const updatedUrl = new URL(frameElement.src);
    updatedUrl.search = filterParams.toString();
    const updatedSrc = updatedUrl.toString();

    const hasFrameSrcChanged = frameElement.src !== updatedSrc;
    if (!hasFrameSrcChanged) {
      // A refresh is requested, but simply updating the src like below won't
      // reload the frame. Instead we need to trigger the reload manually:
      frameElement.reload();
    } else {
      // Update the src, which reloads the frame, in order to ensure the latest
      // selected filters are being used!
      frameElement.src = updatedSrc.toString();
    }
  }

  private createParamsFor(selectedFilters: Record<string, any>) {
    const params = new URLSearchParams();

    params.append('start_date', selectedFilters.startDate.toISOString());
    params.append('end_date', selectedFilters.endDate.toISOString());
    params.append('compare', selectedFilters.compare.toString());

    if (selectedFilters.venueIds.length) {
      selectedFilters.venueIds.forEach((id: string) => {
        params.append('venue_ids[]', id);
      });
    }

    if (selectedFilters.facilityIds.length) {
      selectedFilters.facilityIds.forEach((id: string) => {
        params.append('facility_ids[]', id);
      });
    }

    if (selectedFilters.facilityTypeCategoryIds.length) {
      selectedFilters.facilityTypeCategoryIds.forEach((id: string) => {
        params.append('facility_type_category_ids[]', id);
      });
    }

    if (selectedFilters.activityCategoryIds.length) {
      selectedFilters.activityCategoryIds.forEach((id: string) => {
        params.append('activity_category_ids[]', id);
      });
    }

    if (selectedFilters.customerIds.length) {
      selectedFilters.customerIds.forEach((id: string) => {
        params.append('customer_ids[]', id);
      });
    }

    return params;
  }
}
