import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    summaryPath: String,
  };

  static targets = ['summaryFrame', 'form'];

  refreshTotal() {
    const form = this.formTarget;
    const formData = new FormData(form);
    const params = new URLSearchParams(formData);

    this.summaryFrameTarget.src = this.summaryPathValue + '?' + params;
  }
}
