import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';

// Connects to data-controller="renewals-progress"
export default class extends Controller {
  static values = {
    bookingRequestId: String,
    redirectPath: String,
  };

  static targets = [
    'progressBar',
    'redirectMessage',
    'submitButton',
    'alert',
    'form',
  ];

  connect() {
    this.subscribe();
    this.formTarget.addEventListener('submit', () => {
      this.disableSubmit();
    });
  }

  subscribe = () => {
    consumer.subscriptions.create(
      { channel: 'BookingRenewalsChannel', id: this.bookingRequestIdValue },
      {
        connected: () => {
          // Called when the subscription is ready for use on the server
        },

        disconnected: () => {
          // Called when the subscription has been terminated by the server
        },

        received: (data) => {
          // Called when there's incoming data on the websocket for this channel
          if (data.success) {
            this.updateProgressBar(data.percentage);
          } else {
            this.handleError(data.error);
          }
        },
      }
    );
  };

  updateProgressBar = (percentage) => {
    this.progressBarTarget.style.width = `${percentage}%`;
    this.progressBarTarget.setAttribute('aria-valuenow', percentage);
    if (percentage === 100) this.complete();
  };

  complete = () => {
    this.redirectMessageTarget.classList.remove('invisible');
    window.location.href = this.redirectPathValue;
  };

  handleError = (error) => {
    this.resetProgress();
    this.showAlert(error);
  };

  resetProgress = () => {
    this.updateProgressBar(0);
    this.enableSubmit();
  };

  showAlert = (error) => {
    this.alertTarget.textContent = error;
    this.alertTarget.classList.remove('d-none');
    window.scrollTo(0, 0);
  };

  disableSubmit = () => {
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.textContent = 'Processing...';
  };

  enableSubmit = () => {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.textContent = 'Continue';
  };
}
