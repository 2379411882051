import { Controller } from '@hotwired/stimulus';
import Tooltip from 'bootstrap/js/dist/tooltip';
import {
  handleGeolocationError,
  LOCATION_ERROR_PERMISSION_DENIED_MESSAGE,
} from './helpers/handle_geolocation_error';

export default class extends Controller {
  static targets = ['input', 'button'];

  initialize() {
    if (typeof google != 'undefined') {
      this.initLocation();
    }
  }

  buttonTargetConnected(button) {
    const tooltip = new Tooltip(button, { title: 'Use current location' });

    button.addEventListener('click', () => {
      tooltip.hide();
    });
  }

  // Google maps callback when wiring in the controller
  initLocation() {
    this.geocoder();
  }

  // Google maps geocoder
  geocoder() {
    if (this._geocoder == undefined) {
      this._geocoder = new google.maps.Geocoder();

      if (!navigator.geolocation) {
        alert('Geolocation not supported');
        return false;
      }
    }

    return this._geocoder;
  }

  // action: location#getLocation
  getLocation() {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'denied') {
        window.dispatchEvent(
          new CustomEvent('alert-modal:showModal', {
            detail: {
              icon: 'bi-geo-alt-fill',
              message: LOCATION_ERROR_PERMISSION_DENIED_MESSAGE,
            },
          })
        );
      } else {
        this.getCurrentPosition();
      }
    });
  }

  getCurrentPosition() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latlng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        this.geocoder()
          .geocode({ location: latlng })
          .then((response) => {
            if (response.results[0]) {
              const placeId = response.results[0].place_id;
              const address = response.results[0].formatted_address;

              // check if the target input is a select2, if so it will need
              // to be handled differently
              if ($(this.inputTarget).data('select2')) {
                const option = {
                  id: JSON.stringify({
                    type: 'locations',
                    id: placeId,
                  }),
                  text: address,
                };

                const newOption = new Option(
                  option.text,
                  option.id,
                  false,
                  true
                );
                this.inputTarget.append(newOption);
              } else {
                this.inputTarget.value = address;
              }

              // Fire a change event for the target
              const event = new Event('change', { bubbles: true });
              this.inputTarget.dispatchEvent(event);
            } else {
              alert('No results found for location');
            }
          })
          .catch((geocoderError) => {
            console.error('Geocoder failed', geocoderError);
            alert(
              'Failed to find results for your location. Please try again.'
            );
          });
      },
      (positionError) => {
        handleGeolocationError(positionError, true);
      }
    );
  }
}
