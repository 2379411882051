import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['serviceChargeFields', 'vatNumberField'];

  toggleServiceCharge(e) {
    this.serviceChargeFieldsTarget.disabled = !e.target.checked;
  }

  toggleVatNumberField(e) {
    this.vatNumberFieldTarget.hidden = !e.target.checked;
  }
}
