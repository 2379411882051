import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  static values = {
    reloadOnClose: false,
  };

  hideModal() {
    const $element = $(this.element);
    $element.closest('.modal').next('.modal-backdrop').remove();
    $element.closest('.modal').remove();
    this.reloadOnClose();
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal();
    }
  }

  closeBackground(e) {
    if ($(e.target).closest('.modal-content').length) {
      return;
    }

    if (e.target.classList.contains('modal')) this.hideModal();
  }

  reloadOnClose() {
    if (this.reloadOnCloseValue == true) {
      window.location.reload();
    }
  }
}
