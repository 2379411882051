import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static values = {
    venueId: Number,
    id: Number,
  };

  initialize() {
    this.element.draggable = true;
    this.element.addEventListener('dragstart', this.dragStart.bind(this));
    this.element.addEventListener('dragend', this.dragEnd.bind(this));
    this.element.addEventListener('dragover', this.dragOver.bind(this));
    this.element.addEventListener('dragenter', this.dragEnter.bind(this));
    this.element.addEventListener('dragleave', this.dragLeave.bind(this));
    this.element.addEventListener('drop', this.dragDrop.bind(this));
  }

  dragStart(e) {
    // Stop the event bubbling up through the entire tree
    e.stopPropagation();

    this.element.classList.add('dragging');

    e.dataTransfer.effectAllowed = 'move';

    // Just need to know the id of the record we're moving
    e.dataTransfer.setData(
      'text/plain',
      this.element.dataset.facilitiesDragIdValue
    );
  }

  dragEnd() {
    this.element.classList.remove('dragging');
  }

  dragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    this.element.classList.add('drag-over');
    return false;
  }

  dragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    this.element.classList.add('drag-over');
  }

  dragLeave() {
    this.element.classList.remove('drag-over');
  }

  dragDrop(e) {
    e.stopPropagation();

    try {
      const dragId = e.dataTransfer.getData('text');
      const targetId = this.idValue;

      if (dragId == targetId) return false;

      const expandedIds = $('#facilities-hierarchy')
        .find('.accordion-collapse.collapse.show')
        .closest('.accordion-item')
        .map(function () {
          return $(this).data('facilities-drag-id-value');
        })
        .get();

      const data = {
        hierarchy_item: {
          parent_id: this.element.classList.contains('venue') ? '' : targetId,
          expanded_ids: expandedIds,
        },
      };

      $.ajax({
        method: 'PATCH',
        headers: {
          Accept: 'text/vnd.turbo-stream.html',
        },
        url: `/venues/${this.venueIdValue}/facilities/${dragId}/hierarchy`,
        data: data,
      })
        .done(function (html) {
          Turbo.renderStreamMessage(html);
        })
        .fail(function (error) {
          console.log(error.statusText);
        });
    } finally {
      this.element.classList.remove('drag-over');
    }
  }
}
