import CalendarController from './calendar_controller';
import $ from 'jquery';
import _ from 'lodash';
import { Turbo } from '@hotwired/turbo-rails';
import moment from 'moment/moment';
import { createPopper } from '@popperjs/core';

// Connects to data-controller="operator-bookings-calendar"
export default class extends CalendarController {
  static values = {
    schedulesUrl: String,
    newModalPath: String,
  };

  static targets = ['rightClickTooltip', 'tooltipDestroyBookingForm'];

  initialize() {
    this.setCalendarOptions();
    this.initCalendar();
    this.getSchedulesRequest(this.schedulesUrlValue);
    this.submitEndEventListener();
    this.tabChangeEventListener();
  }

  submitEndEventListener() {
    document.addEventListener('turbo:submit-end', (e) => {
      if (e.detail.success && e.target.id === 'operator_booking')
        this.getSchedulesRequest();
    });

    document.addEventListener('turbo:before-stream-render', (e) => {
      if (e.target.innerHTML.includes('data-load-bookings')) {
        this.getSchedulesRequest();
      }
    });
  }

  tabChangeEventListener() {
    document
      .querySelectorAll('button[data-bs-toggle="tab"]')
      .forEach((tabEl) => {
        tabEl.addEventListener('shown.bs.tab', (event) => {
          if (event.target.dataset.bsTarget === '#operator-bookings') {
            this.getSchedulesRequest();
          }
        });
      });
  }

  setCalendarOptions() {
    this.calendarOptionsValue = {
      ...this.calendarOptionsValue,
      ...this.calendarOptions,
    };
  }

  getSchedulesRequestsResponse(res) {
    this.loadBookings(res);
    if (res['closed-hours']) this.loadClosedHours(res['closed-hours']);
  }

  clickScheduleEvent(e) {
    if (e.schedule.isReadOnly) {
      return;
    }

    this.getUpdateOperatorBookingModal(e);
  }

  beforeCreateScheduleEvent(e) {
    if (e.start.toDate().getTime() >= moment().startOf('day')) {
      this.getOperatorBookingModal(e);
    }
    e.guide.clearGuideElement();
  }

  getOperatorBookingModal(e) {
    $.get(this.newModalPathValue, {
      operator_booking: this.startAndEndTimestampFromCalendarEvent(e),
    })
      .done(function (html) {
        Turbo.renderStreamMessage(html);
      })
      .fail(function (error) {
        console.log(error.statusText);
      });
  }

  getUpdateOperatorBookingModal(e) {
    $.get(e.schedule.raw.showPath, {
      opening_hours: e.start
        ? this.startAndEndTimestampFromCalendarEvent(e)
        : this.startAndEndTimestampFromSchedule(e),
    })
      .done(function (html) {
        Turbo.renderStreamMessage(html);
      })
      .fail(function (error) {
        console.log(error.statusText);
      });
  }

  startAndEndTimestampFromSchedule(e) {
    const { startDate, endDate } = this.startAndEndMomentFromSchedule(e);
    return { start_time: startDate.format('X'), end_time: endDate.format('X') };
  }

  startAndEndMomentFromSchedule(e) {
    const startDate = moment(e.schedule.start.toDate()).set('minute', 0);
    let endDate = moment(e.schedule.end.toDate()).set('minute', 0);
    const duration = moment.duration(endDate.diff(startDate));
    if (duration.asHours() < 2) {
      endDate = startDate.clone().add(1, 'hour');
    }

    return { startDate, endDate };
  }

  beforeUpdateScheduleEvent(e) {
    if (e.start.toDate().getTime() >= moment().startOf('day')) {
      this.getUpdateOperatorBookingModal(e);
    }
  }

  rightClickScheduleEvent(e) {
    if (!this.hasRightClickTooltipTarget) return;
    this.renderRightClickPopover(e);
    e.event.target.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    });
  }

  renderRightClickPopover(e) {
    const trigger = e.event.target;
    this.rightClickTooltipTarget.classList.remove('d-none');
    createPopper(trigger, this.rightClickTooltipTarget);
    const hide = () => {
      this.rightClickTooltipTarget.classList.add('d-none');
      document.removeEventListener('click', hide);
    };
    document.addEventListener('click', hide);
  }

  dispatchEvent(name, calendarEvent) {
    const event = new CustomEvent(name, {
      detail: this.startAndEndDateFromCalendarEvent(calendarEvent),
    });
    window.dispatchEvent(event);
  }

  loadBookings(bookings) {
    const operatorBookings = bookings['operator-bookings'];
    const otherBookings = bookings['other-bookings'];

    const operatorBookingsSchedule = this.jsonToCalendarSchedule(
      operatorBookings,
      'operatorBookings',
      'Operator Booking',
      false
    );

    const otherBookingsSchedule = this.jsonToCalendarSchedule(
      otherBookings,
      'otherBookings',
      'Customer Booking'
    );

    this.calendar.clear();

    this.calendar.createSchedules([
      ...operatorBookingsSchedule,
      ...otherBookingsSchedule,
    ]);
  }

  loadClosedHours(closedHours) {
    if (this.calendar.getViewName() == 'month') return;

    const closedSchedule = this.closedHoursToCalendarSchedule(
      closedHours,
      'closedHours'
    );

    this.calendar.createSchedules(closedSchedule);
  }

  closedHoursToCalendarSchedule(closedHours, calendarId) {
    return closedHours.map((data) => {
      return {
        calendarId: calendarId,
        category: 'time',
        start: this.stripTimeZone(data['start-time']),
        end: this.stripTimeZone(data['end-time']),
        isReadOnly: true,
        customStyle: 'cursor:default',
      };
    });
  }

  jsonToCalendarSchedule(json, calendarId, title, readOnly = true) {
    return json.map((data) => {
      let bri = data;
      bri.calendarId = calendarId;
      bri.title = _.isEmpty(bri.description) ? title : bri.description;
      bri.category = 'time';
      bri.start = this.stripTimeZone(bri['start-time']);
      bri.end = this.stripTimeZone(bri['end-time']);
      bri.isReadOnly = readOnly;
      bri.customStyle = 'margin-right: 8px !important';
      bri.raw = {
        showPath: bri['show-path'],
        deletePath: bri['delete-path'],
        updatePath: bri['update-path'],
      };
      return bri;
    });
  }

  calendars = [
    {
      id: 'closedHours',
      name: 'Closed Hours',
      bgColor: '#c2c6cd80',
      borderColor: '#c2c6cd80',
    },
    {
      id: 'operatorBookings',
      name: 'Operator bookings',
      bgColor: '#82ffa9',
      dragBgColor: '#2d8f48',
      borderColor: '#29bb4f',
    },
    {
      id: 'otherBookings',
      name: 'Customer bookings',
      bgColor: '#e1e1e1',
      dragBgColor: '#e1e1e1',
      borderColor: '#5b5b5b',
    },
  ];

  calendarOptions = {
    calendars: this.calendars,
  };
}
