import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

// Connects to data-controller="photo-upload"
export default class extends Controller {
  static targets = ['form', 'fileInput', 'deleteForm'];

  submit() {
    Rails.fire(this.formTarget, 'submit');
  }

  triggerFilePicker() {
    this.fileInputTarget.click();
  }

  deletePhoto(event) {
    const { id, confirmation } = event.params;
    if (!confirm(confirmation)) return;

    this.deleteFormTargets.forEach((target) => {
      if (target.dataset.photoId != id) return;
      Rails.fire(target, 'submit');
    });
  }

  submitEnd(event) {
    if (!event.detail.success) window.scrollTo(0, 0);
    this.formTarget.reset();
  }
}
