import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="password-visibility"
export default class extends Controller {
  static targets = ['icon', 'field'];

  toggle() {
    const visibleIcon = 'bi-eye';
    const hiddenIcon = 'bi-eye-slash';
    const visibleFieldType = 'text';
    const hiddenFieldType = 'password';

    this.iconTarget.classList.toggle(visibleIcon);
    this.iconTarget.classList.toggle(hiddenIcon);

    this.fieldTargets.forEach((fieldTarget) => {
      if (fieldTarget.getAttribute('type') === visibleFieldType) {
        fieldTarget.setAttribute('type', hiddenFieldType);
      } else {
        fieldTarget.setAttribute('type', visibleFieldType);
      }
    });
  }
}
