import { Controller } from '@hotwired/stimulus';
import { useElementVisibility } from '../mixins/useElementVisibility';
import * as Request from '@rails/request.js';
import BootstrapModal from 'bootstrap/js/dist/modal';
import { useElementEnabled } from '../mixins/useElementEnabled';

const debounce = require('debounce');

// Connects to data-controller="bookings-approvals-edit-modal"
export default class extends Controller {
  static targets = [
    'title',
    'saveButton',
    'hireChargeInput',
    'resetHireChargeButton',
    'customerPriceInput',
    'customerPriceSpinner',
  ];

  static values = {
    baseUrl: String,
  };

  connect() {
    useElementVisibility(this);
    useElementEnabled(this);

    this.modal = new BootstrapModal(this.element);

    this.hireChargeChangeHandler = this.#onHireChargeInputChange.bind(this);
    this.debouncedHireChargeChangeHandler = debounce(
      this.hireChargeChangeHandler,
      500
    );
    this.hireChargeInputTarget.addEventListener(
      'input',
      this.debouncedHireChargeChangeHandler
    );
  }

  disconnect() {
    if (this.hireChargeChangeHandler) {
      this.hireChargeInputTarget.removeEventListener(
        'input',
        this.hireChargeChangeHandler
      );
    }
  }

  /**
   * @param {{
   *  facilityId: string,
   *  bookingInstanceId: string,
   *  bookingLinkId: string,
   *  originalHireCharge: string,
   *  latestHireCharge: string
   * }} detail
   */
  showModal({ detail }) {
    if (!this.modal) {
      console.error(
        'Attempted to show alert modal without it being initialized first.'
      );
      return;
    }

    this.facilityId = detail.facilityId;
    this.bookingInstanceId = detail.bookingInstanceId;
    this.bookingLinkId = detail.bookingLinkId;
    this.originalHireCharge = detail.originalHireCharge;
    this.latestHireCharge = detail.latestHireCharge;

    this.hireChargeInputTarget.value = this.latestHireCharge;

    this.#validateResetButton();
    this.#validateInputs();

    this.#updateCustomerPrice().catch((err) => {
      console.error('Failed to update customer price', err);
    });

    this.onShow();
  }

  onSaveAll() {
    this.dispatchOnSave(true);
  }

  onSave() {
    this.dispatchOnSave();
  }

  dispatchOnSave(applyToAll = false) {
    this.dispatch('onSave', {
      prefix: 'bookings-approvals-edit-modal',
      detail: {
        facilityId: this.facilityId,
        bookingInstanceId: this.bookingInstanceId,
        bookingLinkId: this.bookingLinkId,
        hireCharge: this.hireChargeInputTarget.value || '0.00',
        customerPrice: this.customerPrice,
        applyToAll: applyToAll,
      },
    });
  }

  onReset() {
    this.hireChargeInputTarget.value = this.originalHireCharge;

    this.#validateInputs();
    this.#validateResetButton();

    this.#updateCustomerPrice().catch((err) => {
      console.error('Failed to update customer price', err);
    });
  }

  onShow() {
    this.modal?.show();
    this.hireChargeInputTarget?.focus();
  }

  async #updateCustomerPrice() {
    try {
      this.#setCustomerPriceLoading(true);
      this.customerPriceInputTarget.value = 'Calculating..';

      const url = new URL(
        `${this.baseUrlValue}/customer_price/${this.bookingInstanceId}`
      );

      const hireCharge = this.hireChargeInputTarget.value;
      url.searchParams.set('net_hire_charge', hireCharge);

      const request = await Request.get(url.toString(), {
        responseKind: 'json',
      });

      /**
       * @type {{
       *  customer_price: string
       * }}
       */
      const response = await request.response.json();
      this.customerPrice = response.customer_price;
      this.customerPriceInputTarget.value = this.customerPrice;
    } finally {
      this.#setCustomerPriceLoading(false);
    }
  }

  #onHireChargeInputChange() {
    this.#validateResetButton();

    const areInputsValid = this.#validateInputs();
    if (areInputsValid) {
      this.#updateCustomerPrice().catch((err) => {
        console.error('Failed to update customer price', err);
      });
    }
  }

  #setCustomerPriceLoading(isLoading) {
    this.setElementVisible(this.customerPriceSpinnerTarget, isLoading);
  }

  /**
   * @return {boolean} `true` if valid, otherwise `false`.
   */
  #validateInputs() {
    const isValid = this.hireChargeInputTarget.checkValidity();
    this.hireChargeInputTarget.classList.toggle('is-invalid', !isValid);

    this.customerPriceInputTarget.value = '';
    this.customerPriceInputTarget.classList.toggle('is-invalid', !isValid);

    // Validate the Save button
    if (isValid) {
      // Inputs are valid
      // Now check if hire charge amount has changed since last edit.
      const hireChargeChanged =
        parseFloat(this.latestHireCharge) !==
        parseFloat(this.hireChargeInputTarget.value);
      this.saveButtonTargets.forEach((target) => {
        this.setElementEnabled(target, hireChargeChanged);
      });
    } else {
      // Inputs are invalid, cannot Save
      this.saveButtonTargets.forEach((target) => {
        this.setElementEnabled(target, false);
      });
    }

    return isValid;
  }

  #validateResetButton() {
    // Check if hire charge amount has ever been edited and is different than the one persisted in db.
    const hireChargeEdited =
      parseFloat(this.originalHireCharge) !==
      parseFloat(this.hireChargeInputTarget.value);

    this.setElementEnabled(this.resetHireChargeButtonTarget, hireChargeEdited);
  }
}
