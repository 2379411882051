import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="facility-wizard-facility-type"
export default class extends Controller {
  setFacilityType(e) {
    const { label, value, sportsFacility } = e.target.dataset;
    const event = new CustomEvent('facility-wizard:updateInput', {
      detail: { label: label, value: value, sports_facility: sportsFacility },
    });
    window.dispatchEvent(event);
  }
}
